/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import { useState, useEffect } from 'react';
import { urls } from '../urls'
import dayjs from 'dayjs';
import { formatFileSize } from "../pages/ApplicantDetails/components/ApplicantDetailsBody/DocumentUpload/utils/utils";
import { useTableStore } from 'state';

const myHeaders = new Headers()
myHeaders.append('Content-Type', 'application/json');

const convertFileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64String = (reader.result as string).split(",")[1]; // Remove "data:<MIME type>;base64,"
        resolve(base64String);
      };
      reader.onerror = (error) => reject(error);
    });
  };

export const useDocumentData = (registrationId: string, canFetch: boolean) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [applicantDocs, setApplicantDocs] = useState<any>([]);
    const transactionUrl = urls.TRANSACTION;
    const controller = new AbortController();
    const documentsUrl = `${process.env.NODE_ENV === 'production' ? transactionUrl : ""}/documentService`
    const { signal } = controller;
    const setDisplayAlert = useTableStore(state => state.setDisplayAlert);
    const setSeverity = useTableStore((state) => state.setSeverity)
    const setAlertTitle = useTableStore(state => state.setAlertTitle);
    const setAlertMessage = useTableStore(state => state.setAlertMessage);

    const deleteDocument = async (documentName: string, regId: string) => {
        setLoading(true);
        try {
            
            const response = await (await fetch(`${documentsUrl}/deleteDocumentService/${regId}?documentName=${documentName}`, {
                method: "DELETE",
                credentials: "include",
            }))

            if (response?.ok ){
                setDisplayAlert(true)
                setSeverity("success");
                setAlertTitle("Document Removed.");
                setAlertMessage(`${documentName} has been successfully removed.`);
                fetchApplicantDocs();
            } else {
                setDisplayAlert(true)
                setSeverity("error")
                setAlertTitle("Error");
                const respJSON = await response.json();
                setAlertMessage(respJSON.message);
            }

            return response

        } catch (e) {
            console.error("Error in retrieving document link: ", e);
        } finally {
            setLoading(false);
        }
    }

    const uploadDocument = async (document: any, regId: string) => {
        try {
            const base64File = await convertFileToBase64(document);
    
            let body = {
                registration_id: regId,
                fileName: document.name,
                attachementType: document.type,
                file: base64File,
                fileSize: document.size.toString()
            }

            const response = await (await fetch(`${documentsUrl}/postDocumentUpload`, {
                method: "POST",
                credentials: "include",
                body: JSON.stringify(body),
                signal
            }));

            return response

        } catch (e) {
            console.error("Error in upload: ", e);
        }
    } 

    const getDocumentLink = async (documentName: string, regId: string) => {
        try {
            const response = await (await fetch(`${documentsUrl}/getDocumentService/${regId}?documentName=${documentName}`, {
                method: 'GET',
                credentials: "include",
            })).json();

            const { data } = response
            const documentLink = data.file

            return documentLink

        } catch (e) {
            console.error("Error in retrieving document link: ", e);
        }
    }

    const fetchApplicantDocs = async () => {
        try {
            const listOfDocs = await (await fetch(`${documentsUrl}/getDocumentService/${registrationId}`, {
                method: 'GET',
                credentials: "include",
                signal
            })).json();

            console.log('list of docs: ', listOfDocs);

            const transformData = listOfDocs.documents.map((data) => {
                return {
                    key: data.fileName,
                    id: listOfDocs.id,
                    fileName: data.fileName,
                    date: dayjs(data.insertDate).format("MM/DD/YYYY hh:mm:ss A"),
                    fileSize: formatFileSize(data.fileSize),
                    userName: data.userID
                }
            })

            setApplicantDocs(transformData);

        } catch (err) {
            console.error("error fetching applicant's documents:",err);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        setLoading(true);
        if (canFetch) {
            fetchApplicantDocs();
        }
        return () => controller.abort()
    }, [registrationId]);


    return { applicantDocs, loading, getDocumentLink, uploadDocument, deleteDocument, fetchApplicantDocs };
}
