import { useState, useEffect } from 'react'
import { urls } from '../urls'
import { generateBatchPrintQueryParams } from 'utils/queryTransactionsHelpers';
import { QueryRequestBody } from 'interfaces/common/QueryRequestBody';
import { transformBatchPrintData } from 'utils/queryTransactionsHelpers';
import { useTableStore, useUserStore } from 'state';
import { TableNames } from 'customEnums/TableNames';

enum BatchPrintOk {
    OK = "Successfully updated all the tcns confirmedate"
}

export const useBatchPrint = (
    pageSize: number,
    currentPage: number,
    searchParams: any
) => {

    const [mapped, setMappedData] = useState<any>([])
    const [totalRecords, setTotalRecords] = useState(0);
    const [loading, setLoading] = useState(false)
    const [countError, setError] = useState();
    const userConfig = useUserStore(state => state.userConfig)

    const { userRole } = userConfig;;

    const url = urls.QUERY;

    const pagination = useTableStore(state => state.pagination)
    const { order, orderBy } = pagination[TableNames.BATCH_PRINTING]

    const handlePrintConfirm = async (selected, searchParams) => {
        return new Promise(async (resolve, reject) => {
            const param = searchParams.find((param) => param.column === "transactionDate" || param.column === "lowerDate" || param.column === "upperDate")

            const queryParams = generateBatchPrintQueryParams(orderBy, param, searchParams)

            let queryDataRequest: QueryRequestBody = {
                "queries": [
                    {
                        "queryParams": [
                            ...queryParams,
                            {
                                "value": order,
                                "name": "order"
                            }
                        ],
                        "limit": pageSize,
                        "offset": (currentPage - 1) * pageSize,
                        "queryName": "query_ME_batchprint_unprinted"
                    }
                ]
            }

            let queryCountRequest: QueryRequestBody = {
                "queries": [
                    {
                        "queryParams": [...queryParams],
                        "limit": pageSize,
                        "offset": (currentPage - 1) * pageSize,
                        "queryName": "query_ME_batchprint_unprinted_count"
                    }
                ]
            }
            const body = {
                inputTcns: selected
            }

            try {
                const batchprintUrl = urls.BATCHPRINT;
                let resp = await (await (fetch(`${process.env.NODE_ENV === "production" ? batchprintUrl : ""
                    }/batchprint/tcns-confirmdate`, {
                    method: 'POST',
                    body: JSON.stringify(body),
                    credentials: 'include'
                }))).json()

                console.log(`batch print flow  ${resp.message} === ${BatchPrintOk.OK}`, resp.message === BatchPrintOk.OK)
                if (resp.message === BatchPrintOk.OK) {
                    await fetchUnprinted(queryDataRequest, queryCountRequest)
                    resolve(true)
                } else {
                    console.log('Wrong Message');
                    reject()
                }
            } catch (e: any) {
                console.error('Error submmitinc tcns: ', e)
                reject(e)
            }
        })

    }

    const fetchUnprinted = async (queryDataRequest: any, queryCountRequest: any) => {
        console.log('query batch print fetchUnprinted running')

        try {
            const batchPrintUrl: string = `${process.env.NODE_ENV === "production" ? url : ""}/query`;

            setLoading(true)

            const data = await (await fetch(batchPrintUrl, {
                method: "POST",
                credentials: "include",
                body: JSON.stringify(queryDataRequest)
            })).json();

            const countData = await (await fetch(batchPrintUrl, {
                method: "POST",
                credentials: "include",
                body: JSON.stringify(queryCountRequest)
            })).json();

            const { results } = data['queriesResults'][0];
            const count = countData['queriesResults'][0].results[0]['count'];
            const transformedData = results.map((obj: any, index: number) => transformBatchPrintData(obj, index));
            console.log('batch print flow setMappedData: ', transformedData)
            setMappedData(transformedData);
            setTotalRecords(count);
            setLoading(false);

        } catch (err: any) {
            setError(err);
            setLoading(false);
        }
    }

    useEffect(() => {
        console.log('batch print flow running useBatchPrint')
        const controller = new AbortController()

        const param = searchParams.find((param) => param.column === "transactionDate" || param.column === "lowerDate" || param.column === "upperDate")

        console.log('query batch print useEffect')

        const queryParams = generateBatchPrintQueryParams(orderBy, param, searchParams)

        let queryDataRequest: QueryRequestBody = {
            "queries": [
                {
                    "queryParams": [
                        ...queryParams,
                        {
                            "value": order,
                            "name": "order"
                        }
                    ],
                    "limit": pageSize,
                    "offset": (currentPage - 1) * pageSize,
                    "queryName": "query_ME_batchprint_unprinted"
                }
            ]
        }

        let queryCountRequest: QueryRequestBody = {
            "queries": [
                {
                    "queryParams": [...queryParams],
                    "limit": pageSize,
                    "offset": (currentPage - 1) * pageSize,
                    "queryName": "query_ME_batchprint_unprinted_count"
                }
            ]
        }



        setLoading(true);
        fetchUnprinted(queryDataRequest, queryCountRequest)

        return () => {
            controller.abort();
        }
    }, [url, order, orderBy, currentPage, pageSize, userRole, searchParams])

    return { data: mapped, handlePrintConfirm, totalRecords, countError, loading }
}