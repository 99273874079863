import React, { useEffect } from "react"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Paper, Typography, CircularProgress, LinearProgress } from '@mui/material'
interface CustomModalProps {
    progressVariant?: "determinate" | "indeterminate" | "buffer" | "query" | undefined
    dialogStyles?: any
    open: boolean
    modalTitle?: string
    contentText: string
    contentStyle?: any
    modalActionsConfig?: any
}
export const CustomModal: React.FC<CustomModalProps> = ({modalActionsConfig, progressVariant, dialogStyles, open, modalTitle, contentText, contentStyle}) => {   

  useEffect(() => {
        console.log('debug open custom modal open: ', open)
    }, [open])

    return (
        <Dialog open={open} fullWidth={true} PaperProps={{ sx: dialogStyles }}>
          <DialogTitle>{modalTitle}</DialogTitle>
          <DialogContent sx={contentStyle}>
            <Typography sx={{ whiteSpace: 'pre-line' }}>
              {contentText}
            </Typography>
            {
                progressVariant && (
                    <LinearProgress
                        variant={progressVariant}
                        sx={{ width: "95%", marginRight: "8px" }}
                    />
                )
            }
          </DialogContent>
          {
            modalActionsConfig?.length > 0 && (
                <DialogActions sx={{ p: '1rem 1.5rem', gap: '0.5rem' }}>
                    {
                        modalActionsConfig.map((action) => {
                            return (
                                <Button onClick={action.onClick} variant={action.variant} disabled={action.disabled}>
                                  {action.buttonTitle}
                                </Button>
                            )
                        })
                    }
                </DialogActions>
            )
          }
          {/*<DialogActions sx={{ p: '1rem 1.5rem', gap: '0.5rem' }}>
            <Button onClick={() => handleClose()} variant="outlined">CANCEL</Button>
            <Button onClick={() => handleExtend(dayjs(date).format('YYYY-MM-DD'), getNumExtendedDays(dayjs(), dayjs(date)))} variant="contained">Yes, Extend</Button>
          </DialogActions>*/}
        </Dialog>
      )
}