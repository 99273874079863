import React from "react";
import { DocumentUploadProps } from "../../../../../interfaces/pages/components";
import { Button,  CircularProgress,  Container, Grid2, Typography } from "@mui/material";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { TableNames } from "customEnums/TableNames";
import { DocumentTable } from './DocumentTable';
import { useApplicantDetailsStore } from "state";
import { CustomTableStyle } from "customStyles/CustomTableStyle";
import { NotesStyle } from "../../../../../customStyles/NotesStyle";

export const DocumentUpload: React.FC<DocumentUploadProps> = ({ 
  applicantDocs, 
  loading, 
  getDocumentLink, 
  deleteDocument, 
}) => {
  const documentSelected = useApplicantDetailsStore(state => state.documentSelected)
  const docs = useApplicantDetailsStore(state => state.docs)  
  
  const downloadFile = async (docs: any[]) => {
    try {
      const downloadPromises = docs.map(async (doc) => {
        try {
          const fileUrl = await getDocumentLink(doc.fileName, doc.id);
          const response = await fetch(fileUrl);
          const blob = await response.blob();

          const downloadUrl = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = downloadUrl;
          link.download = doc.fileName;
          document.body.appendChild(link);
          link.click();

          document.body.removeChild(link);
          URL.revokeObjectURL(downloadUrl);

          return { doc, status: "fulfilled" };
        } catch (error) {
          return { doc, status: "rejected", reason: error };
        }
      });

      const results = await Promise.allSettled(downloadPromises);

      results.forEach((result) => {
        if (result.status === "rejected") {
          console.error(`Failed to download ${result.reason.doc.fileName}:`, result.reason);
        } else {
          console.log(`Successfully downloaded ${result.value.doc.fileName}`);
        }
      });

    } catch (error) {
      console.error("Error in downloadFile function: ", error);
    }
  };

  if (loading) {
    return (
      <Grid2 sx={NotesStyle.notesLoadingContainer}>
        <CircularProgress />
      </Grid2>
    );
  }

  return (
    <>
      <Container sx={CustomTableStyle.documentBodyStyle} disableGutters={true} maxWidth={false} >
        <Grid2 sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: "center" }}>
          <Typography variant='h6' color='text.primary'>
            {`DOCUMENT UPLOADS (${applicantDocs.length})`}
          </Typography>
          <Button onClick={() => downloadFile(docs)} size='small' startIcon={<FileDownloadIcon />} disableRipple sx={{ padding: '4px 10px', borderRadius: "6px" }} variant="outlined" disabled={!documentSelected}>
            DOWNLOAD
          </Button>
        </Grid2>
        <DocumentTable
          totalRecords={applicantDocs.length}
          name={TableNames.DOCUMENT_UPLOAD}
          tableData={applicantDocs}
          loading={loading}
          getDocumentLink={getDocumentLink}
          deleteDocument={deleteDocument}
        />
      </Container>
    </>
  );
}