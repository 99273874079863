import React, { useState } from "react";
import { Card, Paper, Toolbar, Container, Typography, Button, Divider, Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Alert, AlertTitle, IconButton, Tooltip } from "@mui/material"
import PersonIcon from '@mui/icons-material/Person';
import { urls, partition } from "urls";
import { getEnvS3Name } from "./ApplicantDetailsFooter/ApplicantDetailsFooterData";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useAudit } from "hooks";
import { Auth } from "aws-amplify";
import { useTableStore } from '../../../state/TableStore';
import { getBackgroundColor, getBorderColor, getIconColor } from "../../../customStyles/common";
import { AlertColor } from '../../../customEnums/AlertColor';
import { ApplicantDetailsStyle } from "../../../customStyles/ApplicantDetailsStyles";
import { useApplicantDetailsStore, useUserStore } from "state";

interface ApplicantDetailsHeaderProps {
  userRole: string,
  userProfile: any,
  applicantImageUrl: string,
  applicantFetchStatus: string
  registrationId: string
  applicantData: any
  usersPermissions: any
  aggregatedResponses: any
  resultsResponses: any
  transactionalInfo: any
  expectedResponse: any
}

interface HeaderItem {
  label: string,
  value: string
}

// manual adjudication is not available for maine & new mexico. 
const noManualAdjudEnv: any = ["dev", "dev2", "mepreprod", "nmpreprod", "meprod", "caresnmdpsprod"]
const ableManualAdjudication = noManualAdjudEnv.includes(process.env.REACT_APP_STAGE)

const HeaderItem: React.FC<HeaderItem> = ({ label, value }) => {
  return (
    <Container maxWidth={false} disableGutters sx={{ display: "flex" }}>
      <Typography sx={{ width: '10rem' }} variant='body2' color='text.secondary'>{label}</Typography>
      <Typography variant='body2' color='text.primary' >{value}</Typography>
    </Container>
  )
}

export const ApplicantDetailsHeader: React.FC<ApplicantDetailsHeaderProps> = ({
  expectedResponse,
  usersPermissions,
  userRole,
  userProfile,
  applicantImageUrl,
  applicantFetchStatus,
  applicantData,
  registrationId,
  aggregatedResponses,
  resultsResponses,
  transactionalInfo }) => {
  console.log(`ApplicantDetailsHeader props: `, {
    expectedResponse,
    usersPermissions,
    userRole,
    userProfile,
    applicantImageUrl,
    applicantFetchStatus,
    applicantData,
    registrationId,
    aggregatedResponses,
    resultsResponses,
    transactionalInfo
  })


  let s3bucket = getEnvS3Name(`${process.env.REACT_APP_STAGE}`)!;
  const [openModalNotification, setOpenNotification] = useState(false);
  const workflowUrl = urls.WORKFLOW
  // const [displayAlert, setDisplayAlert] = useState(false);
  const [displayDecisionAlert, setDisplayDecisionAlert] = useState(false);
  const severity = useTableStore((state) => state.severity)
  const setSeverity = useTableStore((state) => state.setSeverity)
  const alertTitle = useTableStore(state => state.alertTitle);
  const alertMessage = useTableStore(state => state.alertMessage);
  const setAlertTitle = useTableStore(state => state.setAlertTitle);
  const setAlertMessage = useTableStore(state => state.setAlertMessage);
  const applicantHeader = useTableStore(state => state.applicantHeader);
  const setDisplayAlert = useTableStore(state => state.setDisplayAlert);
  const displayAlert = useTableStore(state => state.displayAlert);


  const incompleteStatuses = useApplicantDetailsStore(state => state.incompleteStatuses)
  const config = useUserStore(state => state.config);
  const { nistParsing } = config;
  const incomingTCN = nistParsing.fields.find(item => item.name === "incomingTCN");
  const url = urls.TRANSACTION
  const nistOriginalDisplayId = useApplicantDetailsStore(state => state.nistOriginalDisplayId)
  const nistOriginalParentId = useApplicantDetailsStore(state => state.nistOriginalParentId)
  const { addAuditEvent } = useAudit();

  const [ssnValues, setSsnValues] = useState({
    SSN: userProfile.ssn,
    showSSN: false
  });


  const handleClickShowSSN = () => {
    setSsnValues({ ...ssnValues, showSSN: !ssnValues.showSSN })
  }

  const handleResubmission = async () => {
    setOpenNotification(false);
    const res: any = await resubmission();
    console.log("debug handleResubmission handleExtend res: ", res.message);
    setDisplayAlert(true)
    if (res.message === "Successfully fired API") {
      setSeverity(AlertColor.SUCCESS);
      setAlertTitle("Transaction Resubmitted")
      setAlertMessage("Transaction submitted to AFIS")
    } else {
      setSeverity(AlertColor.ERROR);
      setAlertTitle("Transaction Resubmission Failed")
      setAlertMessage("Please contact the system administrator")
    }
    addAuditEvent("Resubmitted Record", "Details / Adjudication Document", new Date(), registrationId)
  };

  const resubmission = async () => {

    const endpoint = `${process.env.NODE_ENV === 'production' ? workflowUrl : ""}/resubmitTransaction`;

    const body = {
      "Records": [
        {
          "person_id": `${applicantData.parentid}`, //parent_registration_id from association
          "isResubmission": "true",
          "generateTCN": false,
          "s3_bucket_nist": `${s3bucket}`,
          "s3_key_nist": `${applicantData.attachments[0].displayid}`,
          "registration_id": `${registrationId}`,
          "tcn": `${applicantData.tcn}`,
          "tcr": applicantData.descriptors.data[`type${incomingTCN.type}`][incomingTCN.key] || "",
          "s3": {
            "bucket": {
              "name": `${s3bucket}`,
              "arn": `arn:${partition}:s3:::${s3bucket}`
            },
            "object": {
              "key": `${applicantData.attachments[0].displayid}`
            }
          }
        }

      ]
    };


    console.log('debugging resub applicant data', { applicantData, body })


    const options: RequestInit = {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(body),
    };
    try {
      const response = await (await fetch(endpoint, options)).json();
      return response;
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  };

  const handleCloseModal = () => {
    setOpenNotification(false);
  };

  const handleOpenModal = () => {
    setOpenNotification(true)
  }

  const handleCloseAlert = () => {
    setDisplayAlert(false)
  }

  const handleCloseDecisionAlert = () => {
    setDisplayDecisionAlert(false)
  }

  const ResubmitModal = (open) => {
    return (
      <Dialog open={open} color='background.default' >
        <DialogTitle sx={{ width: '37.5rem' }}>ARE YOU SURE YOU WANT TO RE-SUBMIT</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This action cannot be undone. Select 'Yes, Re-submit' to continue, or 'No, cancel' if you would like to return
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ pr: '1.5rem', pb: '1rem' }}>
          <Button variant='outlined' color='primary' sx={{}} onClick={handleCloseModal}>NO, CANCEL</Button>
          <Button variant='contained' color='primary' onClick={handleResubmission}>YES, RE-SUBMIT</Button>
        </DialogActions>
      </Dialog>
    )
  }

  const alertStyle = {
    border: '1px solid',
    borderColor: getBorderColor(severity),
    backgroundColor: getBackgroundColor(severity),
    '.MuiAlert-icon': {
      color: getIconColor(severity),
      ml: "0.1rem"
    },
    '.MuiAlert-action': {
      mr: "0.1rem"
    },
    color: getIconColor(severity),
    paddingLeft: 1,
    paddingRight: 1,
    paddingTop: 2,
    paddingBottom: 2,
    zIndex: 300000,
    position: "fixed",
    top: 67,
    right: 20,
    width: "384px",
    minHeight: "96px",
  }

  console.log('usersPermissions?.canResubmitTransaction', usersPermissions?.canResubmitTransaction)

  console.log('env vars: ', process.env)

  const fingerprintQualityOptions = [
    { label: "No record found", value: "No record found" },
    { label: "Record found", value: "Record found" },
  ];

  enum ApplicantDetailsActionTypes {
    UPDATE_DECISION = "UPDATE_DECISION",
    UPDATE_TAB = "UPDATE_TAB",
    REMOVE_CHANGES = "REMOVE_CHANGES"
  }

  const [decisionText, setDecisionText] = useState("")

  const updateDecision = async (e: any) => {
    const decision = e.target.innerText
    console.log('updateDecision decision: ', decision)
    if (!decision) return;
    setAnchorEl(null)
    const { username } = await Auth.currentAuthenticatedUser()
    const endpoint = `${process.env.NODE_ENV === 'production' ? url : ""}/transaction/taskResponse/${registrationId}`;

    const body: { user: string; decision: string } = {
      user: username,
      decision
    };

    const options: RequestInit = {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(body)
    };

    console.log('updateDecision body: ', body)

    fetch(endpoint, options).then((response: Response) => {
      console.log('update decision success', response);
      setDecisionText(decision)
      setDisplayDecisionAlert(true)
    }).catch((error: Error) => {
      console.error('update decision error', error);
    });
    console.log("update decision ran or e: ", e);

    await addAuditEvent(
      `${ApplicantDetailsActionTypes.UPDATE_DECISION}`,
      { details: body },
      new Date(),
      undefined
    );
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopover = ((e: any) => {
    setAnchorEl(e.currentTarget);
  })

  console.log('debugging tcn: ', { userProfile, applicantData, incompleteStatuses, resultsResponses, expectedResponse, resubDisabled: usersPermissions?.canResubmitTransaction ? resultsResponses?.length !== expectedResponse && incompleteStatuses?.includes(userProfile?.status) ? false : true : true })

  return (
    <>
      {
        displayAlert && (
          <Alert sx={alertStyle} severity={severity} onClose={handleCloseAlert}>
            <AlertTitle >
              {alertTitle}
            </AlertTitle>
            {alertMessage}
          </Alert>
        )
      }
      {
        // DISABLING UNTIL WE HAVE A USE CASE FOR THIS FEATURE
        // displayDecisionAlert && (
        //   <Alert sx={alertStyle} severity="success" onClose={handleCloseDecisionAlert} color="success">
        //     <AlertTitle>
        //       Decision Submitted
        //     </AlertTitle>
        //     Final Decision: {decisionText}
        //   </Alert>
        // )
      }
      <Card sx={ApplicantDetailsStyle.card} elevation={0} >
        <Paper sx={ApplicantDetailsStyle.paper} elevation={0}>
          <Toolbar sx={ApplicantDetailsStyle.toolBar}>
            <Container maxWidth={false} disableGutters sx={ApplicantDetailsStyle.container}>
              <Typography variant="h5" color='text.primary' sx={{ width: '100%' }} height="max-content" >{userProfile.name}</Typography>
              {usersPermissions?.canResubmitTransaction &&
                <Button onClick={handleOpenModal}
                  disabled={!incompleteStatuses?.includes(userProfile?.status)}
                  sx={ApplicantDetailsStyle.resubmitButton}
                  size="medium"
                  color="primary"
                  variant="outlined"
                  aria-label="RE-SUBMIT TRANSACTION">RE-SUBMIT TRANSACTION</Button>
              }
              {/* DISABLING UNTIL WE HAVE A USE CASE FOR THIS FEATURE
                          !ableManualAdjudication && userProfile.status === "Waiting for Manual Adjudication" && <>       
                            <Button disabled={displayDecisionAlert} onClick={handlePopover} sx={{ fontSize:decisionText === "No record found" ? "0.7324rem" : "0.875rem", height: "36px", minWidth: "180px" }} endIcon={<ArrowDropDownIcon />} 
                            size="medium" color="secondary" variant="contained" aria-label="Final Decision">{decisionText ? decisionText : "FINAL DECISION"}</Button>
                            <Menu
                              elevation={0}
                              PaperProps={{
                                  style: {
                                      boxShadow: '0px 3px 5px -1px rgba(124, 124, 130, 0.20)',
                                      
                                  }
                              }}
                              sx={{ borderRadius: '0.375rem', }}
                              open={Boolean(anchorEl)}
                              anchorEl={anchorEl}
                              onClose={() => setAnchorEl(null)}
                              //anchorPosition={{ top: 50, left: 140 }}
                              anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "center"
                              }}
                              transformOrigin={{
                                  vertical: "top",
                                  horizontal: "center"
                              }}>
                              {
                                fingerprintQualityOptions.map((option) => {
                                  return <MenuItem onClick={(e) => updateDecision(e)} sx={{minWidth: "180px"}} value={option.value}>{option.value}</MenuItem>
                                })
                              } 
                            </Menu>
                          </>*/
              }
            </Container>
          </Toolbar>
          <Divider />
          {openModalNotification && <ResubmitModal />}
          {/* <ResubmitModal open={openModalNotification}/> */}
          <Container maxWidth={false} disableGutters sx={ApplicantDetailsStyle.modalContainer}>
            <Box sx={{ minWidth: '105px', height: '116px' }}>
              {
                applicantImageUrl?.length
                  ? <img style={{ height: "100%", width: "100%" }}
                    src={applicantImageUrl}
                    alt='applicant image'
                    loading="lazy"
                  />
                  : <PersonIcon sx={{ height: "100%", width: "100%" }} />
              }
            </Box>
            <Container sx={ApplicantDetailsStyle.demographicsContainer} maxWidth={false} disableGutters>
              {applicantHeader.map((header: any) => {
                return (
                  <Container maxWidth={false} disableGutters sx={{ display: "flex", width: "100%" }}>
                    <Typography sx={{ width: '11.5rem' }} variant='body2' color='text.secondary'>
                      {header.label}
                    </Typography>
                    <Typography variant='body2' color='text.primary'
                      sx={{
                        fontSize: header.key === "ssn" && userProfile.ssn !== "N/A"
                          ? ssnValues.showSSN ? "0.875rem"
                            : "1.3rem" : "",
                        lineHeight: header.key === "ssn" && userProfile.ssn !== "N/A" ? 0 : ""
                      }}>
                      {header.key === "ssn" && userProfile.ssn !== "N/A"
                        ? ssnValues.showSSN ? userProfile.ssn : "---------"
                        : header.key === "secondaryOri"
                          ? userProfile[header.key] ? userProfile[header.key]
                            : "N/A" : userProfile[header.key]
                      }
                      {usersPermissions?.viewAllDetailsData && header.key === "ssn" &&
                        <Tooltip title={ssnValues.showSSN ? "Hide" : "Show"}>
                          <IconButton size="small"
                            aria-label="toggle password visibility"
                            onClick={handleClickShowSSN}
                            edge="end"
                          >
                            {header.key === "ssn" && userProfile.ssn !== "N/A" ? ssnValues.showSSN ? <VisibilityOff sx={{ fontSize: '15px' }} /> : <Visibility sx={{ fontSize: '15px' }} /> : null}
                          </IconButton>
                        </Tooltip>
                      }
                    </Typography>
                  </Container>
                )
              })}
            </Container>
          </Container>
        </Paper>
      </Card>
    </>
  )
}