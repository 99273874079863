
/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import React, { useEffect, useState } from "react";
import { urls } from '../urls'
import { formatDate } from "utils/formatDate";
import { transformRejectData } from "../utils";
import { useTransactionSummaryStore } from "state/TransactionSummaryStore"

export const useResultsResponse = (registrationId: string) => {
    const [resultsResponse, setResultsResponse] = React.useState<any>();
    const [rejectData, setRejectData] = useState<any>([])
    const loadingRejectCodes = useTransactionSummaryStore(state => state.loadingRejectCodes)
    const setLoadingRejectCodes = useTransactionSummaryStore(state => state.setLoadingRejectCodes)
    const [totalRejectCodes, setTotalRejectCodes] = useState(0)
    const reportingUrl = urls.REPORTING
    const personUrl = urls.PERSON;

    useEffect(() => {


        let fetchReportingsUrl = `${process.env.NODE_ENV === 'production' ? reportingUrl : ""}/adjudication/association/${registrationId}`;

        const getAssociations = (): Promise<[]> => {
            const controller = new AbortController();
            const { signal } = controller;
            return new Promise<[]>(async (resolve) => {
                const { associations } = await (await fetch(fetchReportingsUrl, {
                    credentials: "include",
                    signal
                })).json();
                resolve(associations)
            })


        }

        getAssociations().then((associations) => {
            if (associations.length) {
                fetchUrls(associations);
                fetchRejectCodes(associations)
            } else {
                setRejectData([])
                setTotalRejectCodes(0)
            }
        })

        const fetchUrls = async (associations) => {
            const controller = new AbortController();
            const { signal } = controller;
            try {
                const resultsResponses: Object[] = [];

                console.log('debug skeleton loading loadingRejectCodes click results associations: ', associations)

                if (associations.length) {
                    associations.filter((association: any) => association['type'] === 'Response').map(async (repAssociation: any) => {
                        let fetchingPersonUrl = `${process.env.NODE_ENV === 'production' ? personUrl : ""}/registration/${repAssociation.id}`;

                        const data = await (await fetch(fetchingPersonUrl, {
                            credentials: "include",
                            signal
                        })).json();

                        console.log('debug skeleton loading loadingRejectCodes click results associations data: ', { data, forId: repAssociation.id })

                        if (data) {
                            const { descriptors } = data;
                            let type2Field59;
                            if (descriptors.data.type1.field4 !== 'APP' && (descriptors.data.type2.field59 === undefined || descriptors.data.type2.field59 === '')) {
                                type2Field59 = descriptors.data.type1.field4
                            } else {
                                type2Field59 = descriptors.data.type2.field59?.toUpperCase();
                            }
                            const type1Field7 = descriptors.data.type1.field7?.toUpperCase();
                            const date = formatDate(descriptors.data.type1.field5);
                            type2Field59 && resultsResponses.push({ type2Field59, date, type1Field7 });
                        }
                    });
                    setResultsResponse(resultsResponses);
                    console.log('resultsResponses', resultsResponses)
                } else {
                    setRejectData([])
                    setTotalRejectCodes(0)
                }


            } catch (err: any) {
                console.log('ERROR => ', err);
            }
        }

        const fetchRejectCodes = async (associations) => {
            const controller = new AbortController();
            const { signal } = controller;
            try {

                setLoadingRejectCodes(true)
                let mostRecentResponse = associations.findLast(association => association.type === "Response")

                if (mostRecentResponse) {

                    let fetchingPersonUrl = `${process.env.NODE_ENV === 'production' ? personUrl : ""}/registration/${mostRecentResponse.id}`;

                    const data = await (await fetch(fetchingPersonUrl, {
                        credentials: "include",
                        signal
                    })).json();

                    console.log('debug skeleton loading loadingRejectCodes click results associations data: ', { data, forId: mostRecentResponse.id })

                    if (data) {
                        let rejectDataArray = []
                        const { descriptors } = data;
                        console.log('debug skeleton loading loadingRejectCodes descriptiors: ', descriptors)
                        console.log('descriptors.data.type2.field60: ', JSON.stringify({
                            field60Type: typeof descriptors.data.type2.field60,
                            field60: descriptors.data.type2.field60,
                            field60IsObject: typeof descriptors.data.type2.field60 === "object"
                        }, null, 2))
                        if (typeof descriptors.data.type2.field60 === "object") {
                            const results = descriptors.data.type2.field60
                            console.log('debug skeleton loading loadingRejectCodes: results: ', results)
                            const transformedData = results.map((obj: any, index: number) => { return transformRejectData(obj, index) });
                            console.log('debug skeleton loading loadingRejectCodes setting reject data: ', transformedData)
                            rejectDataArray = transformedData
                            setRejectData(transformedData)
                            setTotalRejectCodes(transformedData.length)
                        } else if (rejectDataArray?.length < 1) {
                            console.log('debug skeleton loading loadingRejectCodes setting empty reject data ', JSON.stringify({
                                field60Type: typeof descriptors.data.type2.field60,
                                field60: descriptors.data.type2.field60,
                                field60IsObject: typeof descriptors.data.type2.field60 === "object"
                            }, null, 2))
                            setRejectData([])
                            setTotalRejectCodes(0)
                        }

                    }

                } else {
                    setRejectData([])
                    setTotalRejectCodes(0)
                }


                return () => {
                    controller.abort("request cancelled");
                }

            } catch (err: any) {
                console.log('ERROR => ', err);
            }

        }

    }, [registrationId, reportingUrl, personUrl])

    return { resultsResponse, rejectData, setLoadingRejectCodes, loadingRejectCodes, totalRejectCodes };
};
