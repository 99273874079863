import { Button, Checkbox, Container, RadioGroup, Divider, FormControlLabel, Menu, MenuItem, Paper, Radio, Switch, Typography, radioClasses } from "@mui/material";
// import { useConfig } from "hooks/useConfig";
import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTableStore } from "state/TableStore";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Components } from "state/TableStore";
import { ColumnStateActions } from "customEnums/ColumnState";
import { extractPageName } from '../helpers';
import { useUserStore } from "state";
import { UserGroupLabel } from "customEnums/UserGroups";

export const CustomFilter: React.FC<any> = (
    {
        tableFilter,
        dataIndex,
        enableHeaderButton,
        enableFooterButtons,
        radio,
        cell,
        customFilterAnchorEl,
        setCustomFilterAnchorEl,
        tableName
    }) => {
    const locationObj = useLocation()
    const pageName = extractPageName(locationObj.pathname)

    const columnState = useTableStore(state => state.columnState!)
    const columnStateDispatch = useTableStore(state => state.columnStateDispatch)
    const [showAllFilters, setShowAllFilters] = useState(true)

    const component = useTableStore(state => state.componentConfig)
    const currentCell = useTableStore(state => state.currentCell)
    const setCurrentCell = useTableStore(state => state.setCurrentCell)

    let selectedValue = component[Components.CUSTOM_FILTER].selectedValue
    const currentDataIndex = useTableStore(state => state.currentDataIndex)
    const tableConfig = useTableStore(state => state.tableConfig)

    const { filter, filterList } = tableConfig[tableName]

    const openFilterMenu = Boolean(customFilterAnchorEl)

    const userConfig = useUserStore(state => state.userConfig)
    const { userRole } = userConfig
    
    const popoverId = openFilterMenu ? 'actions-popover' : undefined;

    const handleClose = (e) => {
        e.stopPropagation()
        setCustomFilterAnchorEl(null);
        setCurrentCell("")
    }

    const handleColumnUpdate = useCallback((dataIndex: string, column?: string) => {
        let filterKeys: any = [];

        columnState.page[pageName][tableName].filters.map((filter: any) => {
            Object.keys(filter.filterValues).map((data: any) => {
                filterKeys.push(data)
            })
        })

        console.log('debug handleColumnUpdate running: ', { filter, dataIndex, column, filterKeys, condition: filterKeys.includes(dataIndex)})

        if (filterKeys.includes(dataIndex)) {
            //console.log('columnState status filter: ', dataIndex, pageName)
            columnStateDispatch({ type: ColumnStateActions.TOGGLE_FILTER, filter: dataIndex, page: pageName, column: column, tableName })
        } else {
            columnStateDispatch({ type: ColumnStateActions.TOGGLE_COLUMN, dataIndex, page: pageName, tableName })
        }

    }, [filter])

    const handleHideAllFilterStatus = () => {
        columnStateDispatch({ type: "HIDE_ALL_FILTER_STATUS", tableName })
    }

    const handleShowAllFilterStatus = () => {
        columnStateDispatch({ type: "SHOW_ALL_FILTER_STATUS", tableName })
    }

    const handleResetFilterStatus = () => {
        columnStateDispatch({ type: "RESET_FILTER_STATUS", tableName })
    }

    let tableHeadFilter = openFilterMenu && (dataIndex === currentDataIndex);

    if(currentDataIndex === "securityLevel") console.log('debug env level: ', { lowerLevel: process.env.REACT_APP_LOWER_ENV, filterList})
    return (
        <Menu
            //sx={{width: "261px", height: "196px", bordRadius: 5, gap: "16px"}}
            elevation={10}
            onClose={(e) => handleClose(e)}
            slotProps={{ paper: { sx: { minWidth: 'max-content', backgroundColor: "background.default", border: "none", display: 'flex', flexDirection: "column" } } }}
            id={popoverId}
            open={tableFilter ? openFilterMenu && (dataIndex === currentDataIndex) : openFilterMenu && (cell === currentCell) && (dataIndex === currentDataIndex)}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            anchorEl={customFilterAnchorEl}
            MenuListProps={{ 'aria-labelledby': 'basic-button' }}>
            {
                enableHeaderButton && (
                    <MenuItem>
                        <VisibilityIcon sx={{ marginRight: "10px" }} />
                        <Typography sx={{ flex: 5 }}>Hide Column</Typography>
                    </MenuItem>
                )
            }
            {
                enableHeaderButton && (<Divider />)
            }
            {

                tableHeadFilter && filterList.map((filter: any, index: number) => {
                    if (filter.filterColumn === currentDataIndex)
                        return Object.keys(filter.filterValues).map((key: any, innerIndex: any) => {
                            const isChecked = columnState.page[pageName][tableName]?.filters[index].filterValues[key]?.selected;
                            return process.env.REACT_APP_LOWER_ENV === 'true'
                            ? 
                                <MenuItem key={innerIndex} onClick={(e) => e.stopPropagation()}>
                                
                                    <FormControlLabel   
                                        control={
                                            radio
                                                ?   
                                                <Radio
                                                    checked={selectedValue === filter.filterValues[key].label}
                                                    onChange={(e) => {
                                                        component[Components.CUSTOM_FILTER].handleRadioChange(e, currentDataIndex)
                                                    }}
                                                    color='secondary'
                                                    value={filter.filterValues[key].value}
                                                />
                                                : <Checkbox
                                                    checked={tableFilter ? isChecked : columnState.page[pageName][tableName].columnVisibility[dataIndex]}
                                                    onChange={() => handleColumnUpdate(key, filter.filterColumn)}
                                                    color='secondary'
                                                />
                                        }
                                        label={filter.filterValues[key].label}
                                        disabled={dataIndex === 'checkbox'}
                                    />
                                </MenuItem>
                            
                            : !filter.filterValues[key].devOnly && <MenuItem key={innerIndex} onClick={(e) => e.stopPropagation()}>
                            <FormControlLabel
                                control={
                                    radio
                                        ?   
                                        <Radio
                                            checked={selectedValue === filter.filterValues[key].label}
                                            onChange={(e) => {
                                                component[Components.CUSTOM_FILTER].handleRadioChange(e, currentDataIndex)
                                            }}
                                            color='secondary'
                                            value={filter.filterValues[key].value}
                                        />
                                        : <Checkbox
                                            checked={tableFilter ? isChecked : columnState.page[pageName][tableName].columnVisibility[dataIndex]}
                                            onChange={() => handleColumnUpdate(key, filter.filterColumn)}
                                            color='secondary'
                                        />
                                }
                                label={filter.filterValues[key].label}
                                disabled={dataIndex === 'checkbox'}
                            />
                        </MenuItem>
                        })
                })
            }
            {
                enableFooterButtons && (
                    <MenuItem>
                        <Container disableGutters sx={{ width: "100%", justifyContent: "space-between", display: "flex" }}>
                            {
                                showAllFilters
                                    ? <Button onClick={() => handleHideAllFilterStatus?.()} variant='text'>DESELECT ALL</Button>

                                    : <Button onClick={() => handleShowAllFilterStatus?.()} variant='text'>SELECT ALL</Button>
                            }
                            <Button onClick={() => handleResetFilterStatus?.()} variant='text'>RESET</Button>
                        </Container>
                    </MenuItem>
                )
            }
        </Menu>

    )
}