import React from 'react'
import { TableFooter, Typography, TablePagination } from "@mui/material";
import { useTableStore } from "state/TableStore";
import { useLocation } from "react-router-dom";
import { useMUITheme } from 'theme/ThemeProvider';

export interface CustomTableFooterProps {
    tcns: any[],
    totalRecords: number,
    tableName: string,
    useQueryPagination: boolean
    tableBodyRef: any
}

export const CustomTableFooter: React.FC<CustomTableFooterProps> = ({ tableBodyRef, useQueryPagination, tcns, totalRecords, tableName }) => {
    const { mode } = useMUITheme();
    const pagination = useTableStore((state) => state.pagination)
    const setPagination = useTableStore(state => state.setPagination)
    const detailsTable = useTableStore(state => state.detailsTable)

    const locationObj = useLocation()
    const label = locationObj.pathname.slice(1)

    console.log(`debug useSetConfig  Config CustomTableFooter pagination: `, { useQueryPagination, pagination })

    const differentBgColor = [
        "rb-summary-audit-history", 
        "rb-sub-audit-history",
        "reject-codes",
        "rapback-maintenance"
    ]
    
    return (
        <TableFooter component="div" role="table-footer" sx={{ backgroundColor: !differentBgColor.includes(tableName) ? 'common.black' : 'background.default', display: "flex", justifyContent: label === 'batch-printing' ? "space-between" : 'flex-end', alignItems: "center", padding: detailsTable.includes(label) ? 0 : '0 0.5rem', }}>
            {label === 'batch-printing' &&
                <Typography color='text.primary' variant='body2' sx={{ pl: '1.5rem' }}>{tcns.length} row(s) selected</Typography>
            }

            <TablePagination
                component="div"
                role="footer-pagination"
                sx={{ minHeight: "45px", border: "none" }}
                rowsPerPageOptions={[5, 10, 25, 100]}
                colSpan={9}
                count={totalRecords}
                rowsPerPage={pagination[tableName].rowsPerPage}
                page={useQueryPagination ? pagination[tableName].page - 1 : pagination[tableName].page}
                SelectProps={{
                    inputProps: {
                        'aria-label': 'mappedData per page',
                    },
                    native: true,
                }}
                onPageChange={(e: any, value) => {
                    console.log('debug useSetConfig in pagination useEffect onPageChange tableConfig: ', { tableName, tableBodyRef: tableBodyRef.current })
                    setPagination({ ...pagination, [tableName]: { ...pagination[tableName], page: useQueryPagination ? value + 1 : value } })
                }}
                onRowsPerPageChange={(e: any) => {
                    console.log(`1010101 debug onChange onRowsPerPageChange pagination `, pagination)
                    setPagination({ ...pagination, [tableName]: { ...pagination[tableName], rowsPerPage: parseInt(e.target.value), page: useQueryPagination ? 1 : 0 } })
                }}
            />
        </TableFooter>
    )
}
