/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

const isGovcloud = (process.env.REACT_APP_GOVCLOUD === 'true')
const region = isGovcloud ? 'us-gov-west-1' : 'us-west-2'
export const partition = isGovcloud ? 'aws-us-gov' : 'aws';

export const urls = {
    PERSON: `https://person.${region}.${process.env.REACT_APP_DOMAIN_NAME}`,
    REPORTING: `https://reporting.${region}.${process.env.REACT_APP_DOMAIN_NAME}`,
    TRANSACTION_HISTORY: `https://transactionhistory.${region}.${process.env.REACT_APP_DOMAIN_NAME}`,
    TRANSACTION: `https://transaction.${region}.${process.env.REACT_APP_DOMAIN_NAME}`,
    AUDIT: `https://audit.${region}.${process.env.REACT_APP_DOMAIN_NAME}`,
    NOTES: `https://note.${region}.${process.env.REACT_APP_DOMAIN_NAME}`,
    AGENCY: `https://agency.${region}.${process.env.REACT_APP_DOMAIN_NAME}`,
    GROUP: `https://group.${region}.${process.env.REACT_APP_DOMAIN_NAME}`,
    ROLE: `https://role.${region}.${process.env.REACT_APP_DOMAIN_NAME}`,
    GROUPROLE: `https://grouprole.${region}.${process.env.REACT_APP_DOMAIN_NAME}`,
    LOCK: `https://lock.${region}.${process.env.REACT_APP_DOMAIN_NAME}`,
    RAPBACK: `https://rapback.${region}.${process.env.REACT_APP_DOMAIN_NAME}`,
    QUERY: `https://query.${region}.${process.env.REACT_APP_DOMAIN_NAME}`,
    AUTH: `https://auth.${region}.${process.env.REACT_APP_DOMAIN_NAME}`,
    WORKFLOW: `https://workflow.${region}.${process.env.REACT_APP_DOMAIN_NAME}`,
    CONFIG: `https://config.${region}.${process.env.REACT_APP_DOMAIN_NAME}`,
    BATCHPRINT: `https://batchprint.${region}.${process.env.REACT_APP_DOMAIN_NAME}`,
    USER_ADMIN: `https://useradmin.${region}.${process.env.REACT_APP_DOMAIN_NAME}`,
    HISTORY: `https://pwdhistory.${region}.${process.env.REACT_APP_DOMAIN_NAME}`
}