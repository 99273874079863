import dayjs from 'dayjs'
import { LogConfig, logger } from "../../utils";

export const descendingComparator = (a: any, b: any, orderBy: any) => {
    ////console.log(`debug agency orderBy a ${JSON.stringify(a, null, 2)} ${orderBy}`)
    ////console.log(`debug agency orderBy b ${JSON.stringify(b, null, 2)} ${orderBy}`)
    /*if (orderBy === "agency_email") {
        orderBy = orderBy
    }*/
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

export const extractPageName = (pathname: string): string => {
    const segments = pathname.split('/').filter(Boolean);
    const index = segments.indexOf('app');
    if (index !== -1 && index + 1 < segments.length) {
        return segments[index + 1];
    } else {
        return '';
    }
};

export const getComparator = (order: any, orderBy: any, pageName: string, tableName: string) => {
    console.log('debug stableSort getComparator: ', { order, orderBy })
    if (pageName != "user-management" && tableName !== "document-upload") {
        return order === 'desc'
            ? (a: any, b: any) => descendingComparator(a, b, orderBy)
            : (a: any, b: any) => -descendingComparator(a, b, orderBy);
    } else {
        return order === 'desc'
            ? (a: any, b: any) => -a.localeCompare(b, undefined, { sensitivity: 'base' })
            : (a: any, b: any) => a.localeCompare(b, undefined, { sensitivity: 'base' })
    }

}

export const stableSortNonQuery = (array: any, comparator: (a: any, b: any) => number, orderBy) => {
    const stabilizedThis: any = [];
    array?.forEach((el: any, index: any) => {
        const pair = [el, index];
        stabilizedThis.push(pair);
    });
    console.log('stablizedThis: ', { orderBy, stabilizedThis })
    if (orderBy === "rfp") {
        orderBy = "reasonFingerprinted"
    }

    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0][orderBy!], b[0][orderBy!])
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });


    const debugTablePagination: LogConfig = {
        identifier: "debugTablePagination",
        pageName: "transaction-summary",
        thingToDebug: "stableSortNonQuery params and return value",
        paramsToLog: {
            returnValue: stabilizedThis.map((el) => el[0])
        }
    }
    //loggerdebugTablePagination)
    return stabilizedThis.map((el) => el[0]);
}

