import { useCallback, useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress, Box, Typography, Link, Grid2, Alert, AlertTitle, IconButton, ListItem, List } from '@mui/material'
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { useMUITheme } from "../../../../../theme/ThemeProvider";
import DeleteIcon from '@mui/icons-material/Delete';
import { formatFileSize } from "./utils/utils";
import { getBackgroundColor, getBorderColor, getIconColor } from "../../../../../customStyles/common";
import { useTableStore } from "state";
import DownloadingIcon from '@mui/icons-material/Downloading';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

export const DocUploadModal = ({
    open,
    setOpen,
    regId,
    uploadDocument,
    fetchApplicantDocs
}) => {
    const [droppedFiles, setDroppedFiles] = useState<File[]>([]);
    const { mode } = useMUITheme();
    const isDark = mode === 'dark';
    const severity = useTableStore(state => state.severity);
    const setSeverity = useTableStore(state => state.setSeverity);
    const alertTitle = useTableStore(state => state.alertTitle);
    const alertMessage = useTableStore(state => state.alertMessage);
    const setAlertTitle = useTableStore(state => state.setAlertTitle);
    const setAlertMessage = useTableStore(state => state.setAlertMessage);
    
    const [openAlert, setOpenAlert] = useState(false);
    const [uploadStatus, setUploadStatus] = useState<Record<string, "loading" | "loaded" | "failed">>({});

    const alertStyle = {
        backgroundColor: getBackgroundColor(severity),
        mt: '1rem',
        mb: "1rem",
        borderColor: getBorderColor(severity),
        border: `1px solid`,
        '.MuiAlert-icon': {
            color: getIconColor(severity),
        },
        width: "100%",
    };

    const onDrop = useCallback((acceptedFiles: File[]) => {
        console.log('Dropped Files: ', acceptedFiles);
        setDroppedFiles((prevFiles) => {
            let MAX_FILE_SIZE = 10 * 1024 * 1024
            const validFiles = acceptedFiles.filter(file => {
                // check for file size
                if (file.size > MAX_FILE_SIZE) {
                    console.warn(`File ${file.name} is too large (${formatFileSize(file.size)}). Max allowed is 1MB.`);
                    return false;
                }
                // check for dupes in the dropped list (not the table)
                const fileAlreadyExists = prevFiles.some(existingFile => existingFile.name === file.name);
                if (fileAlreadyExists) {
                    console.warn(`File ${file.name} already exists in the list.`);
                    return false;
                }
                return true;
            });

            return [...prevFiles, ...validFiles]; // only adds valid files
        });

    }, []);


    const uploadDoc = async () => {
        // Tried using Promise.allSettled(); but the API call gets overridden if it's call all at the same time.
        for (const file of droppedFiles) {
            setUploadStatus(prev => ({ ...prev, [file.name]: 'loading' }));
            const resp = await uploadDocument(file, regId);

            console.log('response in uploading document: ', resp);

            if (resp.ok) {
                setUploadStatus(prev => ({ ...prev, [file.name]: 'loaded' }));
            } else {
                setUploadStatus(prev => ({ ...prev, [file.name]: 'failed' }));
            }
        }
    };

    const fileValidator = (file) => {
        let MAX_FILE_SIZE = 10 * 1024 * 1024

        if (file.size > MAX_FILE_SIZE) {
            return {
                code: "file-too-large",
                message: `${file.name} too large`
            };
        }
        const fileExists = droppedFiles.some(existingFile => existingFile.name === file.name);

        if (fileExists) {
            return {
                code: "file-exists",
                message: `${file.name} already exists in the list below`
            }
        }
        return null
    }


    const { getRootProps, getInputProps, isDragActive, fileRejections } = useDropzone({
        onDrop,
        accept: { "application/pdf": [".pdf"] },
        validator: fileValidator
    });

    const fileRejectionItems = fileRejections.map(({ file, errors }) => (
        errors.map((err: any) => (
            <List sx={{ listStyleType: 'disc', listStylePosition: 'inside', pt: 0, pb: 0 }}>
                <ListItem sx={{ display: 'list-item', pt: 0, pb: "0.25rem" }} key={file.path} >
                    {err.message}
                </ListItem>
            </List>
        ))
    ));

    const handleRetry = async (file: any) => {
        setUploadStatus(prev => ({ ...prev, [file.name]: 'loading' }));
        const resp: any = await uploadDocument(file, regId);
        if (!resp?.ok) {
            setUploadStatus(prev => ({ ...prev, [file.name]: 'failed' }));
        } else {
            setUploadStatus(prev => ({ ...prev, [file.name]: 'loaded' }));
        }
    }

    const allFilesUploaded = useMemo(() => {
        return droppedFiles.length > 0 && droppedFiles.every(file => uploadStatus[file.name] === 'loaded');
    }, [droppedFiles, uploadStatus, handleRetry]);

    const uploadInProgress = useMemo(() => {
        return droppedFiles.some(file => uploadStatus[file.name] === 'loading' || uploadStatus[file.name] === 'failed')
    }, [droppedFiles, uploadStatus, handleRetry]);
    
    const removeDroppedFiles = (fileName: string) => {
        setDroppedFiles((prevFiles) => prevFiles.filter(file => file.name !== fileName));
    };

    const handleCancel = () => {
        setOpen(false)
        setDroppedFiles([]);
        setUploadStatus({});
        onDrop([]);
        setOpenAlert(false);
        setSeverity("")
        setAlertTitle(``)
        setAlertMessage(``);
    }

    const modalAction: any = [
        {
            onClick: handleCancel,
            variant: "outlined",
            buttonTitle: "Cancel",
            disabled: false
        },
        {
            onClick: uploadDoc,
            variant: "contained",
            buttonTitle: "UPLOAD DOCUMENT",
            disabled: droppedFiles.length === 0 || uploadInProgress
        }
    ]

    useEffect(() => {
        if (allFilesUploaded) {
            setOpenAlert(true);
            setSeverity("success");
            setAlertTitle("");
            setAlertMessage(`${droppedFiles.length} Files Uploaded`);
            fetchApplicantDocs();
        }
    }, [allFilesUploaded])

    useEffect(() => {
        if (fileRejections.length > 0) {
            setOpenAlert(true);
            setSeverity("error");
            setAlertTitle(`${fileRejections.length} Files Failed`);
        } else {
            setOpenAlert(false);
            setSeverity("");
            setAlertTitle(``);
        }
    }, [fileRejections])



    return (
        <Dialog open={open} fullWidth={true} PaperProps={{ sx: { display: "flex", flexDirection: "column", justifyContent: "space-evenly", minWidth: "900px", backgroundColor: "background.default" } }}>
            <DialogTitle>DOCUMENT UPLOAD</DialogTitle>
            <DialogContent sx={{ display: "flex", flexDirection: "column" }}>
                <Typography sx={{ whiteSpace: 'pre-line' }}>
                    {"Drag and drop document(s), or select the ‘Click to upload’ button to get started. Then select the ‘upload documents’ button."}
                </Typography>
                <Box
                    {...getRootProps()}
                    sx={{
                        border: "2px dashed #ccc",
                        borderRadius: "8px",
                        padding: "20px",
                        textAlign: "center",
                        cursor: "pointer",
                        mt: "1rem",
                        mb: "1rem",
                        backgroundColor: isDragActive ? "#f5f5f5" : isDark ? "#52535a" : "#ffff",
                        "&:hover": { borderColor: "#430099" },
                    }}
                >
                    <input {...getInputProps()} />
                    <UploadFileIcon sx={{ fontSize: 40 }} color="primary" />
                    <Typography variant="body1">
                        <Link sx={{ textDecoration: "underline", color: "primary", cursor: "pointer" }}>
                            Click to upload
                        </Link>{" "}
                        or drag and drop
                    </Typography>
                    <Typography variant="caption" color="textSecondary">
                        PDF (max 10MB)
                    </Typography>
                </Box>
                <Typography>
                    {`${droppedFiles.length} file(s) added`}
                </Typography>
                {openAlert &&
                    <Alert severity={severity} sx={alertStyle} >
                        <AlertTitle>
                            <Typography color={getIconColor(severity)} variant='body1'>
                                {alertTitle}
                            </Typography>
                        </AlertTitle>
                        <Typography color={getIconColor(severity)} variant='body2' sx={{ whiteSpace: 'pre-line' }}>
                            {alertMessage}
                            {!allFilesUploaded && fileRejectionItems}
                        </Typography>
                    </Alert>
                }
                {
                    !allFilesUploaded && droppedFiles.map((file: any) => {
                        return (
                            <>
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        width: "100%",
                                        padding: "1.5rem 1rem 0.25rem",
                                    }}
                                >
                                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                        {
                                            uploadStatus[file.name] === 'failed' ?
                                                <DownloadingIcon sx={{ fontSize: 24, color: "#D1031C" }} />
                                                : <UploadFileIcon sx={{ fontSize: 24 }} color="primary" />
                                        }

                                        <Grid2 sx={{ pl: "1rem" }}>
                                            <Typography variant="body1" color={uploadStatus[file.name] === 'failed' ? "#D1031C" : ""} >
                                                {file.name}
                                            </Typography>

                                            {
                                                <Typography variant="body2" sx={{ fontSize: "14px" }} color={uploadStatus[file.name] === 'failed' ? "#D1031C" : ""}>
                                                    {`${formatFileSize(file.size)} ${uploadStatus[file.name] === 'loading'
                                                            ? '• Uploading...'
                                                            : uploadStatus[file.name] === 'loaded'
                                                                ? '• Upload Complete' :
                                                                uploadStatus[file.name] === 'failed'
                                                                    ? "• Failed"
                                                                    : ''
                                                        }`}
                                                </Typography>
                                            }
                                        </Grid2>
                                    </Box>
                                    <Grid2 display="flex" alignItems="center">
                                        <DeleteIcon sx={{ fontSize: 20, cursor: "pointer" }} color="primary" onClick={() => removeDroppedFiles(file.name)} />
                                        {uploadStatus[file.name] === 'loading' ? <CircularProgress sx={{ ml: "0.5rem" }} size={20} /> : uploadStatus[file.name] === 'failed' &&
                                            <>
                                                <IconButton color="primary" onClick={() => handleRetry(file)}>
                                                    <RestartAltIcon />
                                                </IconButton>
                                            </>
                                        }
                                    </Grid2>
                                </Box>
                            </>
                        );
                    })
                }
            </DialogContent>
            {modalAction?.length > 0 && (
                <DialogActions sx={{ p: '1rem 1.5rem', gap: '0.5rem' }}>
                    {
                        !allFilesUploaded ? modalAction.map((action) => {
                            return (
                                <Button onClick={action.onClick} variant={action.variant} disabled={action.disabled} >
                                    {action.buttonTitle}
                                </Button>
                            )
                        }) : <Button onClick={handleCancel} variant="contained" >CLOSE</Button>
                    }
                </DialogActions>
            )}
        </Dialog>
    );
};