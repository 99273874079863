import { getCurrentEnv } from 'utils/getCurrentEnv';
import { v4 as uuid } from 'uuid' 

export const mapAgencyToApi = (agency: any) => {
    console.log("debug rfp mapAgencyToApi 1agencyManagement agency: ", agency);
    return {
      ori: agency.ori,
      ...(agency.secondaryOri && { secondaryOri: agency.secondaryOri }),
      reasonFingerPrinted: agency.reasonFingerPrinted,
      agency_name: agency.agencyName,
      distribution_method: agency.distributionMethod,
      agency_email: agency.agencyEmail ? agency.agencyEmail : [],
      agency_address: agency.agencyAddress,
      agency_city: agency.agencyCity,
      agency_state: agency.agencyState,
      agency_zipcode: agency.agencyZipcode,
    };
  };

  export let titleToNameMap = {
    ori: (getCurrentEnv === 'me' || getCurrentEnv === 'ga') ? "Primary ORI" : "ORI",
    secondaryOri: "Secondary ORI",
    reasonFingerPrinted: "Reason Fingerprinted",
    agencyName: "Agency Name",
    distributionMethod: "Distribution Method",
    agencyEmail: "E-mail",
    agencyAddress: "Address",
    agencyCity: "City",
    agencyState: "State",
    agencyZipcode: "Zip Code",
    agencyStatus: "Status"
  } as object


  export const distributionMethodOptions: Array<Object> = [
    { label: "Applicant", value: "Applicant" },
    { label: "Agency", value: "Agency" },
    { label: "Both", value: "Both" },
    { label: "No Distribution", value: "No Distribution" },
  ];