import {
    Divider,
    Button,
    CardContent,
    CardActions,
    Typography,
    Grid2
} from "@mui/material";
import { EditorContent } from "@tiptap/react";
import PrintIcon from '@mui/icons-material/Print';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import EditOffIcon from '@mui/icons-material/EditOff';

export const RapsheetContent = ({
    screenHeight,
    rapsheet
}) => {
    return (
        <CardContent sx={{ height: screenHeight <= 1080 ? '19.5rem' : '35rem', overflowY: 'auto', backgroundColor: 'background.default' }} >
            <EditorContent editor={rapsheet} style={{ overflowY: 'auto' }} />
        </CardContent>
    )
}

export const RapsheetHeader = ({
    canPrint,
    printText,
    canCreateNewRedaction,
    redactionExists,
    createdRedaction,
    allResponsesRecieved,
    mode,
    changeView
}) => {
    return (

        <Grid2 display='flex' alignItems='center' gap='1rem' marginRight='1rem'>
            {canPrint &&
                <Button
                    onClick={printText}
                    color='secondary'
                    variant='outlined'
                    sx={{ color: 'text.primary' }}
                    startIcon={<PrintIcon color="secondary" />}
                >
                    PRINT
                </Button>
            }
            {canCreateNewRedaction && !redactionExists && !createdRedaction &&
                <Button
                    color='secondary'
                    sx={{ color: mode === "dark" ? 'text.primary' : "", marginRight: '1rem' }}
                    variant='contained'
                    onClick={() => changeView?.('Original & Redacted')}
                    disabled={(allResponsesRecieved) ? false : true}
                >
                    ADD REDACTION
                </Button>}
        </Grid2>
    )
}

export const RedactButtons = ({ editor, highlighted }) => {
    const createRedaction = () => {
        editor.chain().focus().setHighlight({ color: "gray" }).setColor("gray").run()
    }

    const removeRedaction = () => {
        editor.chain().focus().unsetMark("textStyle").unsetMark("highlight").run()
    }

    const buttonStyle = {
        padding: '6px 8px',
        gap: '8px'
    }

    const iconStyle = {
        width: '20px',
        height: '20px'
    }

    return (
        <>
            <Divider style={{ background: 'rgba(255, 255, 255, 0.12)', width: '100%' }} />
            <CardActions sx={{ backgroundColor: 'background.default' }}>
                <Button disabled={!highlighted} color='primary' sx={buttonStyle} onClick={createRedaction} >
                    <DriveFileRenameOutlineIcon sx={iconStyle} />
                    <Typography variant='body2'>
                        REDACT INFORMATION
                    </Typography>
                </Button>
                <Button disabled={!highlighted} color='primary' sx={buttonStyle} onClick={removeRedaction} >
                    <EditOffIcon sx={iconStyle} />
                    <Typography variant='body2'>
                        REMOVE REDACTION
                    </Typography>
                </Button>
            </CardActions>
        </>
    )
}