import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Typography, Chip } from "@mui/material";
import { useTableStore } from 'state';

export const CustomChip: React.FC<any> = ({ rowData }) => {

    const { chipConfig } = rowData
    const { style, variant, label, text, value } = chipConfig
    console.log('chipConfig: ', chipConfig)
    console.log('chipConfig value check: ', { sign: Math.sign(value) })

    return (
        <>
            {
                value <= 10
                    ? <Chip
                        variant={variant}
                        style={style}
                        label={label} />
                    : <Typography>{text}</Typography>
            }
        </>


    )
}