/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import React from "react";
import { useMUITheme } from "theme/ThemeProvider";
import { AppBar, IconButton, Grid, Tooltip } from '@mui/material';
import DarkMode from '@mui/icons-material/Brightness4';
import LightMode from '@mui/icons-material/Brightness5';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

export const LoginNavbar: React.FC = () => {
  const { mode, toggleTheme } = useMUITheme();
  const isDark = mode === "dark";
  console.log('debug theme', mode)

  return (
    <>
      <AppBar position='static' style={{ backgroundColor: 'inherit' }} elevation={0}>
        <Grid container justifyContent='flex-end' display='flex' pr='1rem' pt='1rem'>
          <Tooltip title="Toggle Theme">
            <IconButton onClick={toggleTheme} aria-label="toggle theme">
              {isDark ? <DarkMode /> : <LightMode />}
            </IconButton>
          </Tooltip>
          <a target="_blank" rel="noopener noreferrer" href={`../../login_user_guide.pdf`} aria-label='help button'>
            <Tooltip title="Help">
              <IconButton aria-label='help button'>
                <HelpOutlineIcon />
              </IconButton>
            </Tooltip>
          </a>
        </Grid>
      </AppBar>
    </>
  );
};
