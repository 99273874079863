/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import { capitalizeFirstLetter } from "./capitalizeFirstLetter";
import { formatDate } from "utils/formatDate";
import dayjs from "dayjs";
import { getFirstSSN } from "./formatSSN";
var advancedFormat = require('dayjs/plugin/advancedFormat')
dayjs.extend(advancedFormat)

const fieldValue = (field: string | number | undefined) => field ?? "N/A";

const displayHeightInFeet = (string: string | undefined) => {
  console.log("height string: ", string);
  let height = string ? `${string[0]}'${string.slice(1)}"` : "";
  console.log("height test: ", height);
  return height;
};

export type UserProfileInfo = ReturnType<typeof generateUserProfileInfo>;
export type PersonalInfo = ReturnType<typeof generatePersonalInformation>;
export type TransactionalInfo = ReturnType<typeof generateTransactionalInformation>;
export type RequestingAgencyInfo = ReturnType<typeof generateRequestingAgencyInfo>;
export type StatusHistories = ReturnType<typeof generateStatusHistories>;
export type EmploymentInfo = ReturnType<typeof generateEmploymentInformation>;

// applicant details header
export const generateUserProfileInfo = (data: object, applicantData: object) => {
  console.log('debug applicantData generateUserProfileInfo: ', data, applicantData)

  let fullName = `${capitalizeFirstLetter(data["firstName"])} ${capitalizeFirstLetter(data["middleName"])} ${capitalizeFirstLetter(data["lastName"])} ${data["suffix"] ?? ""}`;
  console.log('debug applicantData generateUserProfileInfo config fullName: ', fullName)

  return {
    name: fieldValue(fullName),
    suffix: fieldValue(data["suffix"]),
    status: fieldValue(applicantData["attributes"].finalDecision || applicantData["attributes"].transactionStatus),
    ori: fieldValue(data["ori"] || applicantData["attributes"]['ORIG-AGENCY']),
    secondaryOri: fieldValue(data["secondaryOri"]),
    tcn: fieldValue(data["tcn"]),
    ssn: getFirstSSN(fieldValue(data["ssn"])),
    birthDate: formatDate(fieldValue(data["birthDate"]) as string),
    placeOfBirth: fieldValue(data["placeOfBirth"]),
    race: fieldValue(data["race"]),
    gender: fieldValue(data["gender"]),
    height: displayHeightInFeet(fieldValue(data["height"]) as string),
    weight: ` ${fieldValue(data["weight"])} lbs`,
    hairColor: fieldValue(data["hairColor"]),
    eyeColor: fieldValue(data["eyeColor"])
  }
};

export const generatePersonalInformation = (data: object) => {
  console.log(`personalInfo generatePersonalInformation applicantData ${JSON.stringify(data, null, "\t")}`)

  return {
    firstName: fieldValue(data["firstName"]),
    middleName: fieldValue(data["middleName"]),
    lastName: fieldValue(data["lastName"]),
    suffix: fieldValue(data["suffix"]),
    residenceAddress: modifyAddress(data["fullAddress"]),
    mailingAddress: modifyAddress(data["mailingAddress"]),
    ssn: fieldValue(data["ssn"]),
    birthDate: formatDate(fieldValue(data["birthDate"]) as string),
    placeOfBirth: fieldValue(data["placeOfBirth"]),
    countryOfCitizenship: fieldValue(data["countryOfCitizenship"]),
    race: fieldValue(data["race"]),
    gender: fieldValue(data["gender"]),
    height: displayHeightInFeet(fieldValue(data["height"]) as string),
    weight: `${fieldValue(data["weight"])} lbs`,
    hairColor: fieldValue(data["hairColor"]),
    eyeColor: fieldValue(data["eyeColor"]),
    applicantEmail: fieldValue(data["applicantEmail"]),
    applicantPhone: fieldValue(data["applicantPhone"])
  };
};

export const generateTransactionalInformation = (data: object, applicantData: object) => {
  console.log('transactionalInfo applicantData: ', applicantData, data)

  const fileName = getPdfFileName(applicantData["attachments"]);
  console.log('debug filename: ', fileName);

  console.log(
    "debug applicantData applicantData.datelastmodified ?? '' :",
    formatDate(applicantData["datelastmodified"]) ?? ""
  );

  return {
    ori: fieldValue(data["ori"]),
    secondaryOri: fieldValue(data["secondaryOri"]),
    UEID: fieldValue(data["ueid"]),
    incomingTCN: fieldValue(data["incomginTCN"]), //this is tcr check
    transactionNumber: fieldValue(data["tcn"]),
    transactionType: fieldValue(data["transactionType"]),
    assignedAgent: fieldValue("N/A"),
    lastModifiedBy: formatDate(applicantData["datelastmodified"]) ?? "N/A",
    statusTimeout: fieldValue(""),
    transactionTimeout: fieldValue(""),
    reasonFingerprinted: fieldValue(data["reasonFingerprinted"]),
    agency: fieldValue(data["agencyName"]),
    fileName: fileName[0],
    agencyIdentifier: "N/A",
    currentStatus: fieldValue(
      applicantData["attributes"].finalDecision ||
      applicantData["attributes"].transactionStatus
    ),
  };
};

export const generateRequestingAgencyInfo = (data: object) => {
  return {
    requestingAgencyOri: fieldValue(data["secondaryOri"]),
    requestingAgencyName: fieldValue(data["requestingAgencyName"]),
    phoneNumber: "N/A",
    emailAddress: fieldValue(data["emailAddress"]),
    address: fieldValue(data["address"]),
    city: fieldValue(data["city"]),
    state: fieldValue(data["state"]),
    zipCode: fieldValue(data["zipCode"])
  }
}

export const generateEmploymentInformation = (data: object) => {
  const { employerName, address, city, stateOrProvince, postalCode, country } = splitAddress(data["employerInfo"] ?? "")
  return {
    occupation: fieldValue(data["occupation"]),
    employerName,
    employerAddress: address,
    employerCity: city,
    employerState: stateOrProvince,
    employerZipCode: postalCode,
    employerCountry: country
  }
}

const splitAddress = (fullAddress: string) => {
  //String structure of the address: {employerName},{address1},{address2},{city},{stateOrProvince},{postalCode},{country}
  const parts = fullAddress?.split(",").map(part => part.trim());

  const employerName = parts[0] || "N/A";
  const address1 = parts[1] || "N/A";
  const address2 = parts[2] || "";
  const city = parts[3] || "N/A";
  const stateOrProvince = parts[4] || "N/A";
  const postalCode = parts[5] || "N/A";
  const country = parts[6] || "N/A";

  return {
    employerName: employerName ? fieldValue(employerName) : "N/A",
    address: modifyAddress(`${address1} ${address2}`),
    city,
    stateOrProvince,
    postalCode,
    country
  };
};

export const generateAggregatedResponses = (applicantData: any) => {
  return applicantData.descriptors.data.type2.field75;
};

const modifyAddress = (address: string) => {
  let modifiedAddress
  if (address) {
    modifiedAddress = address?.replace(/,/g, ', ');
    modifiedAddress = modifiedAddress.trim().replace(/,\s*$/, '');
    return modifiedAddress;
  } else {
    return "N/A"
  }
};

const getPdfFileName = (data: any) => {
  return data
    .map(attachment => attachment.attributes?.fileName)
    .filter(Boolean);
}

export const generateStatusHistories = (applicantData: any, index: any) => {
  console.log(
    "debug data applicantData in generateStatusHistories: ",
    applicantData
  );
  return {
    key: index,
    id: applicantData.uuid,
    status: applicantData.status,
    statusDate: applicantData.insert_date
      ? dayjs(applicantData.insert_date).format("MM/DD/YYYY hh:mm:ss A")
      : "",
    agency: applicantData.agency,
  };
};

export const parseName = (name: string) => {
  console.log(`parseName name: ${name}`)
  let arr = name.split(",")
  console.log(`parseName arr: `, arr)
  let firstName, middleName
  let lastName = arr[0]
  let firstMiddle = arr[1].trim().split(" ")

  firstName = firstMiddle[0]
  middleName = firstMiddle[1]
  return [firstName, middleName, lastName]
}
