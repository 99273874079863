import React, { useEffect, useState } from "react";
import { EmailDeliveryProps } from "../../../../../interfaces/pages/components";
import { Button, CircularProgress, Container, Grid2, Typography } from "@mui/material";
import { TableNames } from "../../../../../customEnums/TableNames";
import { EmailDeliveryTable } from './EmailDeliveryTable';
import { useTableStore } from "../../../../../state";
import { CustomTableStyle } from "../../../../../customStyles/CustomTableStyle";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import dayjs from "dayjs";
import { useEmailActivity } from "hooks/useEmailActivity";
import { NotesStyle } from "../../../../../customStyles/NotesStyle";

export const EmailDelivery: React.FC<EmailDeliveryProps> = ({ registrationId, status }) => {
  const { emailActivity, emailCount, resendEmail, loading } = useEmailActivity(registrationId as string)

  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [diffInDays, setDiffInDays] = useState<number>(0)
  const [expiredRecord, setExpiredRecord] = useState<boolean>(false);

  const tableConfig = useTableStore(state => state.tableConfig)
  const maxResendCount = tableConfig[TableNames.EMAIL_DELIVERY]?.maxResendCount;
  const setDisableButton = useTableStore(state => state.setDisableButton)
  const disableButton = useTableStore(state => state.disableButton)

  useEffect(() => {
    if (emailCount > 0) {
      const expiredDate = emailActivity?.[0]?.expiredDate;
      if (expiredDate) {
        const today = dayjs();
        const diffInDays = expiredDate.diff(today, "day");

        setDiffInDays(diffInDays);
        if (diffInDays <= 0) {
          setDisableButton(true);
          setExpiredRecord(true);
        }
      }
    }
  }, [emailActivity, disableButton]);

  const toggleVisibility = () => {
    setIsVisible(!isVisible)
  }

  if (loading) {
    return (
      <Grid2 sx={NotesStyle.notesLoadingContainer}>
        <CircularProgress />
      </Grid2>
    );
  }

  return (
    <>
      <Container sx={CustomTableStyle.emailDeliveryStyle} disableGutters={true} maxWidth={false} >
        <Grid2 sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Typography variant="h6" fontSize="1.125rem" fontWeight="500" sx={{ letterSpacing: "1.5px" }}>
            SECURE EMAIL DELIVERY
          </Typography>
        </Grid2>
        {emailCount === 0 ?
            <Typography variant="body1" fontWeight={400} letterSpacing="0.15px" lineHeight="150%" >
              Secure Email Delivery does not apply to this record at this time.
            </Typography>
            :
            <>
              <Typography variant="body1" fontWeight={400} letterSpacing="0.15px" lineHeight="150%" >
                {expiredRecord ?

                  `This record is expired and no longer eligible for Secure Email Delivery. Use the Secure Email Activity Log to view the history of system-generated secure emails, 
                including previously sent and undelivered messages. Resend attempts are unavailable for expired records.`
                  :
                  `Use the Secure Email Activity Log to track the delivery status of system-generated secure emails and manage resend attempts. 
              Verify successful deliveries, identify undelivered emails, and resend them as needed. 
              All emails displayed here are exclusively for secure document delivery and are limited to ${maxResendCount} resend(s) attempts within ${diffInDays} day(s) of the original email being sent.`}
              </Typography>
              <Grid2 sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: "center" }}>
                <Typography variant='subtitle2' fontSize="1rem" color='text.primary'>
                  SECURE EMAIL ACTIVITY LOG
                </Typography>
              </Grid2>
              <EmailDeliveryTable totalRecords={emailCount} name={TableNames.EMAIL_DELIVERY} tableData={emailActivity} resendEmail={resendEmail} />
            </>
        }
      </Container>
    </>
  );
}