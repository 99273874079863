// import { useConfig } from '../hooks/useConfig';
import dayjs from 'dayjs';
import { useLoginStore } from "state/LoginStore";
import { useTableStore, useUserStore } from 'state';

export const GetMaintenanceSchedule = (tableName: string) => {
    const setAlertTitle = useLoginStore((state) => state.setAlertTitle)
    const setAlertDescription = useLoginStore((state) => state.setAlertDescription)
    const setSeverity = useLoginStore((state) => state.setSeverity)
    const setOpenAlert = useLoginStore((state) => state.setOpenAlert)
    const config = useUserStore(state => state.config)


    const startDate = dayjs(config.scheduledMaintenance.startDate).startOf("day").format("MMM DD, YYYY hh:mm:ss A");
    const endDate = dayjs(config.scheduledMaintenance.endDate).endOf("day").format("MMM DD, YYYY hh:mm:ss A");
    const currentDate = dayjs();

    const isBeforeMaintenance = currentDate.isBefore(startDate);
    const isAfterMaintenance = currentDate.isAfter(endDate);
    const isDuringMaintenance = currentDate.isAfter(startDate) && currentDate.isBefore(endDate);

    let maintenanceActive;

    if (startDate && endDate) {
        if (isDuringMaintenance) {
            maintenanceActive = true
            setSeverity("warning")
            setOpenAlert(true)
            setAlertTitle("Maintenance Scheduled.")
            setAlertDescription("This website is under maintenance, please check back later.")
        } else {
            maintenanceActive = false
        }
    }

    return { maintenanceActive, startDate, endDate, currentDate, isAfterMaintenance, isBeforeMaintenance, isDuringMaintenance }

}
