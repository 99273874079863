import { urls } from "../urls";
import { useTableStore } from "state";
import { AlertColor } from "../customEnums/AlertColor";
import { useAudit } from "../hooks/useAudit";
import { v4 as uuid } from 'uuid'

export const useAgencyManagement = () => {
    const url = urls.AGENCY
    const { addAuditEvent } = useAudit();
    const setAlertTitle = useTableStore(state => state.setAlertTitle);
    const setAlertMessage = useTableStore(state => state.setAlertMessage);
    const setSeverity = useTableStore((state) => state.setSeverity)
    const setOpenNotification = useTableStore((state) => state.setOpenNotification)
    const agencyUrl = `${process.env.NODE_ENV === 'production' ? url : ""}/agency`

    const createAgency = async (agencyId: string, agency: any) => {
        const body = {
            id: agencyId,
            isActive: true,
            json_data: agency,
        }

        try {
            const resp = await fetch(`${agencyUrl}/${agencyId}`, {
                method: "POST",
                credentials: "include",
                body: JSON.stringify(body),
            });

            const error = await resp.text();

            if (resp?.ok) {
                setAlertTitle('Success')
                setAlertMessage('A new agency was successfully created!')
                setSeverity(AlertColor.SUCCESS)
                setOpenNotification(true)
                addAuditEvent("Added Agency", `Added data: ${JSON.stringify(agency)}`, new Date(), agencyId);
            } else {
                setAlertTitle('Agency Creation Error');
                setAlertMessage(error)
                setSeverity(AlertColor.ERROR)
                setOpenNotification(true)
            }

            return resp
        } catch (err) {
            console.log('Creating Agency Error: ', err);
        }
    }


    const updateAgency = async (agencyId: string, agency: any) => {
        const endpoint = `${process.env.NODE_ENV === 'production' ? url : ""}/agency/update/${agencyId}`;
        const body = {
            id: agencyId,
            isActive: true,
            json_data: agency,
        }
        const options: RequestInit = {
            method: "POST",
            credentials: "include",
            body: JSON.stringify(body.json_data)
        }

        try {
            const resp = await fetch(endpoint, options)
            
            if (resp.ok) {
                setAlertTitle('Success')
                setAlertMessage('Agency record was successfully updated.')
                setSeverity(AlertColor.SUCCESS)
                setOpenNotification(true)
                addAuditEvent("Updated Agency", `Updated data: ${JSON.stringify(agency)}`, new Date(), agencyId);
            }

            return resp
        } catch (err) {
            console.log('Creating Agency Error: ', err);
            setAlertTitle('Agency Update Error')
            setAlertMessage('Please try contact your administration')
            setSeverity(AlertColor.ERROR);
            setOpenNotification(true)
        }
    }

    const deleteAgency = async (agencyId) => {
        const endpoint = `${process.env.NODE_ENV === 'production' ? url : ""}/agency/${agencyId}`;

        const options: RequestInit = {
            method: "DELETE",
            credentials: "include",
        };

        try {
            const response = await (await fetch(endpoint, options)).json();
            return response;
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    }

    return { createAgency, updateAgency, deleteAgency }
}