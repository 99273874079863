/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import { useEffect, useRef, useState } from "react";
import { Auth } from "aws-amplify";
import ApiService from "utils/apiService";
import { formatPermissionsAfterFetch } from "components/UserPermissions/permissionsConfig";
import { urls } from "urls";
import { useLoginStore, useTableStore, useUserStore } from "state";
import { handleUnauthorized } from "utils/handleUnauthorized";
import { useAuth } from "./useAuth";
import { group } from "node:console";

const getUserRoleLabel = (roles: any, value: string): { label: string | undefined, value: string | undefined } => {
  const role = roles.find(role => role.value === value);
  return role !== undefined ? {
    label: role.label,
    value
  } : {
    label: value,
    value
  };
};

export const useUserCognito = () => {
  const [userRole, setUserRole] = useState<string | string[]>();
  const [userGroup, setUserGroup] = useState<string | string[]>();
  const [usersPermissions, setUsersPermissions] = useState<any>();
  const [userGivenName, setUserGivenName] = useState()
  const [userFamilyName, setUserFamilyName] = useState()
  const [groups, setGroups] = useState();
  const [userEmail, setUserEmail] = useState<string>()
  const groupUrl = urls.GROUP;
  const roleUrl = urls.ROLE;
  const groupRoleUrl = urls.GROUPROLE;
  const groupRoles: any = [];
  const userConfig = useUserStore(state => state.userConfig)
  const tableConfig = useTableStore(state => state.tableConfig)
  const config = useUserStore(state => state.config)
  const { logout } = useAuth();
  const { loggedIn } = userConfig
  const [groupResponse, setGroupResponse] = useState<any>()
  const [userGroups, setUserGroups] = useState<any>()
  const [singleGroup, setSingleGroup] = useState<any>()
  const [userName, setUserName] = useState<string>()
  const groupCallCount = useRef(0)
  const setAlertTitle = useLoginStore((state) => state.setAlertTitle)
  const setAlertDescription = useLoginStore((state) => state.setAlertDescription)
  const setOpenAlert = useLoginStore((state) => state.setOpenAlert)
  const setSeverity = useLoginStore((state) => state.setSeverity)
  const termsAccepted = useLoginStore(state => state.termsAccepted)

  const setUserConfig = useUserStore(state => state.setUserConfig)

  const controller = new AbortController();
  const { signal } = controller;

  const groupsService = new ApiService(
    `${process.env.NODE_ENV === "production" ? groupUrl : ""}/group`
  );
  const rolesService = new ApiService(
    `${process.env.NODE_ENV === "production" ? roleUrl : ""}/role`
  );
  const groupRoleService = new ApiService(
    `${process.env.NODE_ENV === "production" ? groupRoleUrl : ""}/groupRole`
  );

  useEffect(() => {


    const getUserInfoFromCognito = async () => {
      console.log(`debug groupsService group call running getUserInfoFromCognito loggedIn ${loggedIn} useConfig: `, userConfig)
      const user = await Auth?.currentAuthenticatedUser();
      setUserName(user.username)

      const idTokenPayload = user.signInUserSession.idToken.payload;
      if (idTokenPayload.given_name) {
        setUserGivenName(idTokenPayload.given_name)
      }
      if (idTokenPayload.family_name) {
        setUserFamilyName(idTokenPayload.family_name)
      }
      if (idTokenPayload.email) {
        setUserEmail(idTokenPayload.email)
      }

      setUserGroups(idTokenPayload["cognito:groups"]);
      setSingleGroup(idTokenPayload["cognito:groups"].filter((group) => group.startsWith('mc_')));

      const callGroupTillSuccess = async (): Promise<any> => {
        groupCallCount.current = groupCallCount.current + 1
        console.log('debug groupsService call count: ', groupCallCount.current)
        return new Promise<{ data: [], status: number }>((async (resolve, rejct) => {
          const { data, status } = await groupsService.get()
          console.log('debug groupsService status: ', status)
          if (status === 200) {
            console.log('debug groupsService got 200 returniing: ', { data, status })
            resolve({ data: data.data, status })
          } else {
            if (groupCallCount.current < 5) {
              console.log('debug groupsService call recalling...')
              callGroupTillSuccess()
            } else {
              console.log('debug groupsService max attempts reached logging out...')
              logout()
              setSeverity('error')
              setAlertTitle("Login Error")
              setAlertDescription("An unxepected error has occurred. Please retry to login.")
              setOpenAlert(true)
              setUserConfig({ ...userConfig, loggedIn: false })
            }

          }

        })).then((response) => {
          if (response.status === 200) {
            console.log('debug groupsService return response: ', response)
            setGroupResponse(response)
          }
        })

      }
      if (termsAccepted) callGroupTillSuccess()
      console.log('debug groupsService response data: ', groupResponse)
    }

    console.log(`debug group call running getUserInfoFromCognito loggedIn ${loggedIn} useConfig: `, userConfig)

    //AppBar callues useAuth for logout() which recalls useUserCognito and hits end points again that we already have saved 
    if (loggedIn /*&& !userConfig.userRole*/) {
      getUserInfoFromCognito();
    } else {
      console.log(`debug userConfig loggedIn ${loggedIn}`)
    }

    return () => controller.abort();
  }, [loggedIn, termsAccepted]);

  useEffect(() => {

    const setUserData = async () => {
      console.log('debug groupsService setUserData: ', groupResponse)
      if (groupResponse) {

        const { data, status } = groupResponse
        try {


          console.log('debug groupsService response data: ', data)
          const allGroups = data
          setGroups(allGroups);

          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          const resp = await groupRoleService.get(singleGroup.join())
          handleUnauthorized(resp.message, logout);

          groupRoles.push(resp.data)
          const formattedPermissions = await formatPermissionsAfterFetch(
            groupRoles
          );

          setUsersPermissions(formattedPermissions);

          if (userGroups.length) {
            const singleGroup = userGroups.filter((group) => group.startsWith('mc_'));
            const userRole = singleGroup[0];
            console.log('debug groupsService getUserRoleLabel config', getUserRoleLabel(config.cognito.userRoles, userRole))
            const labelUserRole = getUserRoleLabel(config.cognito.userRoles, userRole);
            if (labelUserRole) {
              setUserRole(labelUserRole.label);
              setUserGroup(labelUserRole.value);
            }
            // if (userRole) setUserRole(userRole);
          } else {
            console.log(`httpOnly ${userName} doesn't belong to any groups`);
          }
        } catch (err: any) {
          console.log('err: ', err);
          return { errorMessage: err.message };
        }


      }
    }

    setUserData()

  }, [groupResponse, singleGroup, userGroups, userName])

  console.log(`debug userConfig useUserCognito types loggedIn ${loggedIn}: `, {
    userRole: typeof userRole, usersPermissions: typeof usersPermissions, groups: typeof groups, userGivenName: typeof userGivenName, userFamilyName: typeof userFamilyName, userEmail: typeof userEmail
  })

  return { userRole, userGroup, usersPermissions, groups, userGivenName, userFamilyName, userEmail };
};
