/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

export const FormattedSSN = (ssn: any) => {
    if (ssn !== undefined) {
        ssn = ssn.replace(/\D/g, '');
        ssn = ssn.replace(/^(\d{3})/, '$1-');
        ssn = ssn.replace(/-(\d{2})/, '-$1-');
        ssn = ssn.replace(/(\d)-(\d{4}).*/, '$1-$2');
        return ssn;
    } else {
        return ssn
    }
}

export const getFirstSSN = (ssn: string | number): string | number => {
  console.log("generate person data ssn:", ssn);

  if (!ssn || ssn === "N/A") return ssn;

  const rawSSNString = ssn.toString().trim();

  const firstSSN = rawSSNString.includes(",") 
    ? rawSSNString.split(",")[0].trim() 
    : rawSSNString;

  if (firstSSN.includes("-")) return firstSSN;

  return firstSSN.replace(/^(\d{3})(\d{2})(\d{4})$/, "$1-$2-$3");
};

