/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import React, { useCallback, useEffect } from 'react';
import { Container, Typography, Alert, AlertTitle } from '@mui/material'
import { CustomTableTitleAndAlertProps } from '../../interfaces/pages/components/CustomTableTitleAndAlertProps';
import { AlertColor } from '../../customEnums/AlertColor';
import { useTableStore } from '../../state/TableStore';
import { useLocation } from "react-router-dom";
import { getBackgroundColor, getBorderColor, getIconColor } from '../../customStyles/common/AlertStyles';
import { useUserStore } from 'state';

export const CustomTableTitleAndAlert: React.FC<CustomTableTitleAndAlertProps> = ({ name, setDisplayNoSearchResults, setSearched, setSearchParams }) => {
    const severity = useTableStore(state => state.severity);
    const alertTitle = useTableStore(state => state.alertTitle);
    const alertMessage = useTableStore(state => state.alertMessage);
    const openNotification = useTableStore((state) => state.openNotification)
    const setAlertTitle = useTableStore(state => state.setAlertTitle);
    const setAlertMessage = useTableStore(state => state.setAlertMessage);
    const setSeverity = useTableStore((state) => state.setSeverity)
    const displayNoSearchResults = useTableStore(state => state.displayNoSearchResults);
    const setOpenNotification = useTableStore(state => state.setOpenNotification);
    const needsAttention = useTableStore(state => state.needsAttention);
    const setNeedsAttention = useTableStore(state => state.setNeedsAttention);
    const userConfig = useUserStore(state => state.userConfig)
    const setUserConfig = useUserStore(state => state.setUserConfig)

    const locationObj = useLocation()

    const containerStyle = {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%",
        marginBottom: '1rem',
    }

    const alertStyle = {
        backgroundColor: getBackgroundColor(severity),
        mb: '1rem',
        borderColor: getBorderColor(severity),
        border: `1px solid`,
        '.MuiAlert-icon': {
            color: getIconColor(severity),
        },
        '& .MuiAlert-action': {
            color: needsAttention ? 'warning.contrastText' : '',
        },
        width: "384px",
        position: 'absolute',
        zIndex: 300000,
        top: 70,
        right: 20,
    };

    useEffect(() => {
        if (displayNoSearchResults) {
            setAlertMessage('Search criteria does not return any results. Please update your search criteria and try again.');
            setSeverity(AlertColor.INFO);
            setAlertTitle('No Results');
        }
    })

    const handleClose = useCallback(() => {
        console.log('debug handle close alert: ', { setDisplayNoSearchResults, setSearched, setSearchParams })
        setDisplayNoSearchResults?.(false)
        setSearched?.(false)
        setSearchParams?.([])
        setOpenNotification(false)
        setNeedsAttention(false)
        setUserConfig({ ...userConfig, userClosedRbSub: true })
    }, [setDisplayNoSearchResults, setSearched, setSearchParams])

    return (
        <Container maxWidth={false} disableGutters sx={containerStyle}>
            <Typography variant='h2' color='text.primary' fontSize={`1.5rem`} fontWeight="600">
                {name}
            </Typography>
            {
                severity === undefined ? null :
                    (openNotification) ?
                        <Alert severity={severity} sx={alertStyle} onClose={handleClose} >
                            <AlertTitle>
                                <Typography color={getIconColor(severity)} variant='body1'>
                                    {alertTitle}
                                </Typography>
                            </AlertTitle>
                            <Typography color={getIconColor(severity)} variant='body2'>
                                {alertMessage}
                            </Typography>
                        </Alert> : null
            }
        </Container>
    )
}