import { create } from "zustand"
import { persist, createJSONStorage } from 'zustand/middleware'

export interface ApplicantDetailsState {
    tcn: string
    ori: string
    nistOriginalDisplayId: string
    nistOriginalParentId: string
    incompleteStatuses: string[]
    setNistOriginalDisplayId: Function
    setNistOriginalParentId: Function
    setTcn: Function
    setOri: Function
    setIncompleteStatuses: Function
    documentSelected: boolean
    setDocumentSelected: Function
    docs: Array<any>
    setDocs: Function
}

const initialState: ApplicantDetailsState = {
    nistOriginalDisplayId: "",
    nistOriginalParentId: "",
    tcn: "",
    ori: "",
    incompleteStatuses: [],
    documentSelected: false,
    docs: [],
    setNistOriginalDisplayId: () => { },
    setNistOriginalParentId: () => { },
    setTcn: () => { },
    setOri: () => { },
    setIncompleteStatuses: () => { },
    setDocumentSelected: () => { },
    setDocs: () => {

    }
}

export const useApplicantDetailsStore = create<ApplicantDetailsState>((set, get) => ({
    ...initialState,
    setNistOriginalParentId: (nistOriginalParentId: string) => set({ nistOriginalParentId }),
    setNistOriginalDisplayId: (nistOriginalDisplayId: string) => set({ nistOriginalDisplayId }),
    setTcn: (tcn: string) => set({ tcn }),
    setOri: (ori: string) => set({ ori }),
    setIncompleteStatuses: (incompleteStatuses: string[]) => set({ incompleteStatuses }),
    setDocumentSelected: (documentSelected: boolean) => set({ documentSelected }),
    setDocs: (docs: object[]) => set({ docs })
}))