import { Typography } from '@mui/material';
import React from 'react';
import {  useTableStore } from 'state';
import { tableCellTypogrphyStyle } from "../styles"

interface CustomTableCellProps {
    cell: { dataIndex: string, label: string }
    value: string,
    children: any
}

export const SimpleTableCell: React.FC<CustomTableCellProps> = ({ cell, value, children }) => {
    const showValue = ["deliveryStatus"]
    
    return (
        <Typography sx={{...tableCellTypogrphyStyle, fontSize: `14px`}}>
            {!showValue.includes(cell.dataIndex) && value && value}
            {children}
        </Typography>

    )
}

