import React, { ReactNode, useCallback, useEffect, useReducer, useState } from "react"
import { useLocation } from "react-router-dom";
import { useTableStore, useApplicantDetailsStore, useCustomModalStore } from "../../../../../state";
import { TableNames } from "../../../../../customEnums/TableNames";
import { CustomTable } from "../../../../../common";
import { extractPageName } from "../../../../../common/CustomTable/helpers";
import { useSetConfig } from "../../../../../hooks";
import PrintIcon from '@mui/icons-material/Print';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DeleteIcon from '@mui/icons-material/Delete';
import { SelectAll } from "interfaces/pages/components";
import { selectAllReducer } from "pages/BatchPrinting/helpers";
import { Order } from "@customTypes/OrderType";
import printJS from "print-js";

export const DocumentTable: React.FC<any> = ({ name, tableData, totalRecords, getDocumentLink, deleteDocument }) => {
  const locationObj = useLocation()
  const pageName = extractPageName(locationObj.pathname)
  const tableConfig = useTableStore(state => state.tableConfig)
  const pagination = useTableStore(state => state.pagination)
  const setPagination = useTableStore(state => state.setPagination)
  const selected = useTableStore(state => state.selected)
  const setSelected = useTableStore(state => state.setSelected)
  const setDocumentSelected = useApplicantDetailsStore(state => state.setDocumentSelected)
  const setDocs = useApplicantDetailsStore(state => state.setDocs)
  const docs = useApplicantDetailsStore(state => state.docs)
  const setOpenCustomModal = useCustomModalStore(state => state.setOpenCustomModal)
  const setCustomModalTitle = useCustomModalStore(state => state.setCustomModalTitle)
  const setContentText = useCustomModalStore(state => state.setContentText)
  const setContentStyle = useCustomModalStore(state => state.setContentStyle)
  const setModalActionsConfig = useCustomModalStore(state => state.setModalActionsConfig)

  const printDoc = async (e, row) => {
    console.log('row document to print: ', row);
    const regId = row.id;
    const docName = row.fileName;

    const fileUrl = await getDocumentLink(docName, regId);
    if (!fileUrl) {
      console.error("No file URL returned");
      return;
    }

    try {
      const response = await fetch(fileUrl);
      const pdfBlob = await response.blob();
      const pdfUrl = URL.createObjectURL(pdfBlob);
      console.log("Generated PDF URL:", pdfUrl);

      printJS({ printable: pdfUrl, type: 'pdf', showModal: true });

    } catch (error) {
      console.error("Error fetching the document for printing:", error);
    }
  };

  const openDoc = async (e: any, row: any) => {
    const regId = row.id;
    const docName = row.fileName;
    const fileUrl = await getDocumentLink(docName, regId);
    if (fileUrl) {
      const newTab = window?.open(fileUrl, '_blank');
      newTab?.focus();
    }
  };

  const deleteDoc = async (e, row) => {
    const regId = row.id;
    const docName = row.fileName
    setOpenCustomModal(false);
    const resp = await deleteDocument(docName, regId);

    if (resp?.ok) {
      setDocs([]);
      dispatchSelectAll({ type: "DESELECT_ALL", page: page })
    }
  }

  const buttonCellHandlers = {
    delete: {
      clickHandler: (e, row) => {
        setOpenCustomModal(true);
        setContentText("This action cannot be undone. Select ‘Yes, Delete’ to continue, or ‘No, cancel’ if you would like to return.")
        setContentStyle({ display: "flex", flexDirection: "column", justifyContent: "space-evenly", })
        setCustomModalTitle("ARE YOU SURE YOU WANT TO DELETE?");
        setModalActionsConfig([
          {
            onClick: () => setOpenCustomModal(false),
            variant: "outlined",
            buttonTitle: "NO, CANCEL"
          },
          {
            onClick: (e) => deleteDoc(e, row),
            variant: "contained",
            buttonTitle: "YES, DELETE",
          }
        ])
      }
    }
  }

  const actionsConfig: { actionIcon?: ReactNode, actionLabel: string, actionHandler?: Function }[] = [
    {
      actionIcon: <PrintIcon sx={{ color: 'text.secondary', mr: '1rem' }} fontSize="small" />,
      actionLabel: "Print",
      actionHandler: printDoc
    },
    {
      actionIcon: <OpenInNewIcon sx={{ color: 'text.secondary', mr: '1rem' }} fontSize="small" />,
      actionLabel: "View",
      actionHandler: openDoc
    },
    {
      actionLabel: "_divider",
      actionHandler: undefined
    },
    {
      actionIcon: <DeleteIcon sx={{ color: 'text.secondary', mr: '1rem' }} fontSize="small" />,
      actionLabel: "Delete",
      actionHandler: buttonCellHandlers.delete.clickHandler
    },
  ];

  const { tableHeaderCells, page, rowsPerPage } = useSetConfig(pageName, { page: 0, rowsPerPage: 10 }, TableNames.DOCUMENT_UPLOAD, {}, actionsConfig, buttonCellHandlers);

  const initialSelectAllState = {
    pages: [{ page: 1, selectAll: false }] as SelectAll[]
  }
  const [selectAllState, dispatchSelectAll] = useReducer(selectAllReducer, initialSelectAllState)

  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    let documents: any = []
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;

    const currentPageRows = tableData.slice(startIndex, endIndex);

    if (e.target.checked) {
      dispatchSelectAll({ type: "SELECT_ALL", page: page })
      documents = [...currentPageRows]

      setDocumentSelected(true)

    } else {
      dispatchSelectAll({ type: "DESELECT_ALL", page: page })
      documents = []
      setDocumentSelected(false);
    }
    setDocs(documents)
  }

  const handleDeselectAll = () => {
    setDocs([])
  }

  const handleDownloadRecordSelect = (params: any) => {
    const { event, doc } = params;

    console.log(`debug checkbox document upload flow handlePrintRecordSelect params: `, { params });

    event.stopPropagation();

    const docList = [...docs];
    const itemIndex = docList.findIndex((row: any) => row.key === doc);

    if (itemIndex !== -1) {
      const updatedDocList = docList.filter((_, index) => index !== itemIndex);
      setDocs(updatedDocList);
    } else {
      const row = tableData.find((row: any) => row.key === doc);
      if (row) {
        setDocs([...docList, row]);
      }
    }
    if (docList.length <= 1 && itemIndex !== -1) {
      setDocumentSelected(false);
    } else {
      setDocumentSelected(true);
    }

    console.log('Document Upload handleDownloadRecordSelect set tcns: ', docs);
  };

  const handleRowClick = (e: any, row?: any) => {
    console.log(`debug checkbox batch print flow handleRowClick: `, { row })
    handleDownloadRecordSelect({ event: e, doc: row.key, id: row.key });
    handleClick(e, row.key)
  }

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = [...selected, id];
    } else {
      newSelected = selected.filter((item) => item !== id);
    }

    console.log('Updated selected array:', newSelected);

    setSelected(newSelected);
  };

  return (
    <>
      {
        tableHeaderCells &&
        <CustomTable
          tableConfig={tableConfig}
          tableName={name}
          pageSize={rowsPerPage}
          totalRecords={totalRecords}
          rows={tableData}
          hideSearch={true}
          currentPage={page}
          savedSearchState={[{}, () => { }]}
          selected={selected}
          setSelected={setSelected}
          handleSelectAll={handleSelectAll}
          selectAllState={selectAllState}
          handleDeselectAll={handleDeselectAll}
          handleTableItemSelect={handleDownloadRecordSelect}
          rowClickHandler={(e, row) => handleRowClick(e, row)}
        />
      }
    </>
  )
}