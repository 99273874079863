/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import { useState, useEffect, useRef } from 'react'
import { urls } from '../urls'
import { generateRapBackQueryParams, transformRapBackData } from 'utils/queryTransactionsHelpers';
import { QueryRequestBody } from 'interfaces/common/QueryRequestBody';
import { useTableStore, useUserStore } from "../state";
import { handleUnauthorized } from 'utils/handleUnauthorized';
import { useAuth } from './useAuth';
import { PageNames } from 'interfaces/config';

export const useQueryRapBack = (
    pageSize: number,
    currentPage: number,
    searchParams: any,
    userRole,
    tableName,
    pageName = "rapback-summary"
) => {

    const [mappedData, setMappedData] = useState<any>()
    const [totalRecords, setTotalRecords] = useState(0);
    const [loading, setLoading] = useState(false)
    const [countError, setError] = useState();
    const columnState = useTableStore(state => state.columnState)
    const userConfig = useUserStore(state => state.userConfig)
    const tableConfig = useTableStore(state => state.tableConfig)
    const runFilters = useTableStore(state => state.runFilters)
    const setRunFilters = useTableStore(state => state.setRunFilters)
    const pagination = useTableStore((state) => state.pagination)

    const runCount = useRef(0);
    const page = useRef(currentPage)
    const pageSizeRef = useRef(pageSize)
    const { isAdmin } = userConfig;
    const { logout } = useAuth();

    const url = urls.QUERY

    const { orderBy, order } = pagination[PageNames.RAPBACK_SUMMARY]


    useEffect(() => {
        runCount.current = runCount.current + 1;
        console.log('debug reset table useQueryRapback: ', {
            pageSize,
            currentPage,
            searchParams,
            userRole,
            tableName,
            order,
            orderBy,
            runCount: runCount.current,
            runFilters,
            columnState: columnState.page["rapback-summary"]["rapback-summary"]?.filters?.length,
            pageName
        })
        const controller = new AbortController()

        let filters = columnState.page["rapback-summary"]["rapback-summary"]?.filters
        const filterLabels: string[] = []

        if (filters) {
            filters.forEach((filter) => {
                let filterValueKeys = Object.keys(filter.filterValues)
                filterValueKeys.forEach((key) => {
                    console.log('key: ', key)
                    if (filter.filterValues[key].selected === true) {
                        filterLabels.push(filter.filterValues[key].label)
                    }
                })
            })
        }

        console.log(`debug useQueryRapback useEffect dependencies fetchData conditionals: ${JSON.stringify({
            pageName,
            userRole,
            filterLabels,
            //columnStateRbSummary: columnState.page["rapback-summary"],
            currentRunCount: runCount.current,
            runFilters,
            pageCurrent: page.current,
            currentPage,
            pageSizeCurrent: pageSizeRef.current,
            pageSize,
            firstC: runCount.current > 2,
            secondC: !runFilters,
            thirdC: searchParams?.length < 1,
            fourthC: page.current === currentPage,
            fifthC: pageSizeRef.current === pageSize,
            wholeC: runCount.current > 2 && !runFilters && searchParams?.length < 1 && page.current === currentPage && pageSizeRef.current === pageSize,

            runSearch: userRole && filterLabels.length > 0
        }, null, 2)}`)

        let runQuery

        if (pageSizeRef.current === pageSize || searchParams.length >= 0) {
            runQuery = true
        } else if (pageName != "rapback-summary" || (runCount.current > 2 && !runFilters && searchParams?.length < 1 && page.current === currentPage /*&& pageSizeRef.current === pageSize*/)) {
            console.log('debug useQueryRapback first condition false')
            runQuery = false
        } /*else if (searchParams.length >= 0) {
            console.log('debug useQueryRapback 2nd condition true')
            runQuery = true
        }*/ else if ((runCount.current <= 2 || runCount.current > 2 && runFilters || (searchParams.length > 0)) || page.current != currentPage || pageSizeRef.current != pageSize) {
            console.log('debug useQueryRapback third condition: ', userRole && filterLabels.length > 0)
            runQuery = userRole && filterLabels.length > 0
        }

        /*if (page.current != currentPage || pageSizeRef.current != pageSize) {
            runQuery = userORIs && userORIs.length && userRole && filterLabels.length > 0
        }*/


        if (runQuery) {

            if (pageName != "transaction-summary") console.log('101010110 debug useQuery useQueryRapback useEffect dependencies RUNNING FETCH')


            const queryParams = generateRapBackQueryParams(filterLabels, isAdmin, orderBy, order, searchParams)

            let queryDataRequest: QueryRequestBody = {
                "queries": [
                    {
                        "queryParams": [...queryParams],
                        "limit": pageSize,
                        "offset": (currentPage - 1) * pageSize,
                        "queryName": process.env.REACT_APP_RAPBACK_SUMMARY_TABLE_QUERY!,
                        "transformData": true
                    }
                ]
            }

            let queryCountRequest: QueryRequestBody = {
                "queries": [
                    {
                        "queryParams": [...queryParams],
                        "limit": pageSize,
                        "offset": (currentPage - 1) * pageSize,
                        "queryName": process.env.REACT_APP_RAPBACK_SUMMARY_TABLE_COUNT_QUERY!
                    }
                ]
            }

            const fetchRapBacks = async (queryDataRequest: any, queryCountRequest: any) => {
                try {
                    const rapBackUrl: string = `${process.env.NODE_ENV === "production" ? url : ""}/query`;

                    let i: number = 1;
                    setLoading(true)

                    const data = await (await fetch(rapBackUrl, {
                        method: "POST",
                        credentials: "include",
                        body: JSON.stringify(queryDataRequest)
                    })).json();

                    handleUnauthorized(data.message, logout);


                    const countData = await (await fetch(rapBackUrl, {
                        method: "POST",
                        credentials: "include",
                        body: JSON.stringify(queryCountRequest)
                    })).json();

                    const { results } = data['queriesResults'][0];
                    const count = countData['queriesResults'][0].results[0]['count'];
                    setMappedData(results);
                    // const transformedData = results.map((obj: any, index: number) => transformRapBackData(obj, index));
                    // setMappedData(transformedData);
                    setTotalRecords(count);
                    setLoading(false);
                    setRunFilters(false)

                } catch (err: any) {
                    setError(err);
                    setLoading(false);
                }
            }


            console.log('debug pagination useQueryRapBack dependencies: ', {
                userRole,
                rowsPerPage: pageSize,
                page: currentPage,
                order,
                orderBy,
                searchParamsLength: searchParams.length,
                filterLabels
            })
            if (pageName != "transaction-summary") fetchRapBacks(queryDataRequest, queryCountRequest)
        }


        return () => {
            controller.abort();
        }
    }, [
        userRole,
        pageSize,
        currentPage,
        order,
        orderBy,
        searchParams,
        searchParams?.length,
        runFilters,
        columnState.page["rapback-summary"]["rapback-summary"]?.filters?.length,
        tableName,
        pageName
    ])

    const handleResetToNotViewed = async (e, row) => {
        console.log('handleReset row: ', row)
        let id = row.key
        console.log(`debug updateRecord running ${JSON.stringify({ id }, null, 2)}`)
        const updateRecord: string = `${process.env.NODE_ENV === "production" ? urls.REPORTING : ""}/last-viewed`
        const body = {
            "id": id,

        }

        console.log('updateRecord called with body: ', JSON.stringify(body, null, 2));

        let resp;
        try {
            resp = await (await fetch(updateRecord, {
                method: "DELETE",
                credentials: "include",
                body: JSON.stringify(body)
            })).json()

            console.log('updateRecord resp: ', resp)
        } catch (e) {
            console.log('Error Updating Record ', resp)
        }
        return resp
    }


    const upsertViewedRecord = async (id: string, user: string) => {
        console.log(`debug updateRecord running ${JSON.stringify({ id, user }, null, 2)}`)
        const updateRecord: string = `${process.env.NODE_ENV === "production" ? urls.REPORTING : ""}/last-viewed`
        const body = {
            "id": id,
            "modified_user": user
        }

        console.log('updateRecord called with body: ', JSON.stringify(body, null, 2));

        let resp;
        try {
            resp = await (await fetch(updateRecord, {
                method: "POST",
                credentials: "include",
                body: JSON.stringify(body)
            })).json()

            console.log('updateRecord resp: ', resp)
        } catch (e) {
            console.log('Error Updating Record ', resp)
        }
        return resp
    }

    return { data: mappedData, runCount: runCount.current, totalRecords, countError, loading, upsertViewedRecord, handleResetToNotViewed }
}