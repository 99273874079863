import React from "react"
import { TextField, TextFieldVariants } from '@mui/material';


interface MFATextInputProps {
    id: string,
    label: string,
    value?: string,
    textInput: (e) => void,
    required: boolean,
    autoFocus?: boolean,
    inputRef?,
    error?: boolean,
    helperText?: string,
    variant: TextFieldVariants | undefined,
    inputProps: any,
    type: string,
    autoComplete: string,
    name: string,
    onKeyPress: any

}

export const MFATextInput: React.FC<MFATextInputProps> = ({
    id,
    label,
    value,
    textInput,
    required = true,
    autoFocus = true,
    inputRef,
    error,
    helperText = "",
    variant,
    inputProps,
    type,
    autoComplete,
    name,
    onKeyPress
}) => {
    return (
        <TextField
            id={id}
            label={label}
            value={value}
            name={name}
            onChange={textInput}
            onKeyPress={onKeyPress}
            required={required}
            fullWidth
            autoFocus={autoFocus}
            variant={variant}
            inputRef={inputRef}
            error={error}
            autoComplete={label !== "Verification Code" ? autoComplete : "off"}
            type={type}
            helperText={helperText}
            InputLabelProps={{ style: { color: 'text.secondary' } }}
            InputProps={{
                ...inputProps,
                style: { color: 'text.primary' }

            }}
        />
    )
}