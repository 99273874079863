import React, { useCallback, useRef } from 'react'
import { Container, Button, Tooltip, Badge, IconButton, Menu, MenuItem, Typography, Divider, FormControlLabel, Checkbox, Switch, Grid, Box } from "@mui/material";
import SettingsIcon from '@mui/icons-material/Settings';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { useTableStore } from "state/TableStore";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { CustomTableStyle } from 'customStyles/CustomTableStyle';
import { ColumnStateActions } from "customEnums/ColumnState";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

export const TableTools = ({ tableName, resetTable }) => {
    const locationObj = useLocation()
    const pageName = locationObj.pathname.slice(1).replace('app/', "")
    const columnState = useTableStore(state => state.columnState!)
    const columnStateDispatch = useTableStore(state => state.columnStateDispatch)
    const headerButtonConfig = useTableStore((state) => state.headerButtonConfig)
    const { text, buttonLabel, handler, disabled, headerButtonIcon } = headerButtonConfig
    const tableConfig = useTableStore(state => state.tableConfig)
    const { useQueryPagination, manageColumns, filter, tables, columnVisibility, filterList, tableHeaderCells } = tableConfig[tableName]
    const setPagination = useTableStore(state => state.setPagination)
    const pagination = useTableStore(state => state.pagination)
    const setRunFilters = useTableStore(state => state.setRunFilters)
    const setFiltersApplied = useTableStore(state => state.setFiltersApplied)
    const filtersApplied = useTableStore(state => state.filtersApplied)
    const defaultOrderBy = useTableStore(state => state.defaultOrderBy)

    //Local State
    const [filterAnchorEl, setFilterAnchorEl] = useState<null | HTMLElement>(null)
    const openFilter = Boolean(filterAnchorEl);
    const [showAllColumns, setShowAll] = useState(true)
    const [numFiltersDeselected, setNumFiltersDeselected] = useState(0)
    const [mcAnchorEl, setMCAnchorEl] = useState(null);
    const [openFilters, setOpenFilters] = useState(columnState.page[pageName][tableName]?.filters?.length > 0 ? Array(columnState.page[pageName][tableName].filters.length).fill(true) : []);
    const STATE = process.env.REACT_APP_CONFIG_STATE!;
    const menuItemsRef = useRef<(HTMLLIElement | null)[]>([]);
    const [focusedIndex, setFocusedIndex] = useState<number | null>(null);
    const openManageColumns = Boolean(mcAnchorEl);
    const manageColumnsId = openManageColumns ? 'manage-columns-popover' : undefined;

    useEffect(() => {
        const columnVisibility: Array<any> = []
        Object.keys(columnState.page[pageName][tableName]?.columnVisibility).map((key: string) => {
            columnVisibility.push(columnState.page[pageName][tableName].columnVisibility[key])
        })
        if (columnVisibility.some((key: any) => key !== "middleName" && key === false)) {
            setShowAll(false)
        } else {
            setShowAll(true)
        }

        const stateFilterList: Array<any> = []
        const filtersDeselected: Array<any> = []
        columnState.page[pageName][tableName].filters?.map((filter: any, index) => {
            Object.keys(filter.filterValues).map((key: string) => {
                if (!filter.filterValues[key].selected) {
                    filtersDeselected.push(filter.filterValues[key])
                    setNumFiltersDeselected(filtersDeselected.length)
                } else {
                    if (!filtersDeselected.length) {
                        setNumFiltersDeselected(filtersDeselected.length)
                    }
                }
                stateFilterList.push(filter.filterValues[key])
            })
        })
    }, [columnState, filter])

    const handleColumnUpdate = useCallback((dataIndex: string, isChecked: boolean, column?: string, filterLabel?: string, selection?: any) => {
        console.log('101010010111101010 handleColumnUpdate params:  ', { dataIndex, column, isChecked, columnState })
        let filterKeys: any = [];

        columnState.page[pageName][tableName].filters.map((filter: any) => {
            Object.keys(filter.filterValues).map((data: any) => {
                filterKeys.push(data)
            })
        })

        console.log(`debug TableTools filter column ${column} dataIndex: ${dataIndex}`)
        console.log(`debug TableTools filter column ${filterKeys}.includes(${dataIndex}): ${filterKeys.includes(dataIndex)}`)

        if (filterKeys.includes(dataIndex)) {
            console.log('columnState status filter: ', dataIndex, pageName)
            columnStateDispatch({ type: ColumnStateActions.TOGGLE_FILTER, filter: dataIndex, page: pageName, column: column, tableName })
        } else if (filterLabel && selection.anySelected) {
            columnStateDispatch({ type: ColumnStateActions.HIDE_ALL_FILTERS, filter: dataIndex, page: pageName, column: column, tableName })
        } else if (filterLabel && !selection.anySelected) {
            columnStateDispatch({ type: ColumnStateActions.SHOW_ALL_FILTERS, filter: dataIndex, page: pageName, column: column, tableName })

        } else {
            columnStateDispatch({ type: ColumnStateActions.TOGGLE_COLUMN, dataIndex, page: pageName, tableName })
        }

    }, [filter])

    const handleHideAll = () => {
        columnStateDispatch({ type: ColumnStateActions.HIDE_ALL_COLUMNS, page: pageName, tableName })
        setShowAll(true)
    }

    const handleShowAll = () => {
        console.log('debug TableTools filter column show all running')
        columnStateDispatch({ type: ColumnStateActions.SHOW_ALL_COLUMNS, page: pageName, tableName })
        setShowAll(false)
    }

    const handleResetFilterStatus = () => {
        if (filtersApplied) {
            columnStateDispatch({ type: ColumnStateActions.RESET_FILTERS, page: pageName, filterList, tableName })
            setRunFilters(true);
        } else {
            columnStateDispatch({ type: ColumnStateActions.RESET_FILTERS, page: pageName, filterList, tableName })
        }
    }

    const handleOpenFilter = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation()
        setFilterAnchorEl(e.currentTarget)
    }

    const handleCloseFilter = (e: any) => {
        e.stopPropagation()
        setFilterAnchorEl(null)
    }

    const handleReset = useCallback(() => {
        columnStateDispatch({ type: ColumnStateActions.RESET_COLUMNS, page: pageName, columnVisibility, tableName })
        columnStateDispatch({ type: ColumnStateActions.RESET_FILTERS, page: pageName, filterList, tableName })
    }, [columnVisibility])

    const handleOpenManageColumns = (e: any) => {
        setMCAnchorEl(e.currentTarget)
    }

    const handleCloseManageColumns = (e: any) => {
        e.stopPropagation()
        setMCAnchorEl(null);
    };

    const toggleFilterMenu = (index) => {
        const updatedOpenFilters = [...openFilters];
        updatedOpenFilters[index] = !updatedOpenFilters[index];
        setOpenFilters(updatedOpenFilters);
    };

    const handleApplyFilter = ((e) => {
        handleCloseFilter(e);
        setRunFilters(true);
        setFiltersApplied(true);
        setPagination({ ...pagination, [tableName]: { ...pagination[tableName], page: 1 } })

    })

    const handleMenuKeyDown = (event: React.KeyboardEvent<HTMLUListElement>) => {
        const itemCount = menuItemsRef.current.length;
        switch (event.key) {
            case 'ArrowDown':
                event.preventDefault();
                setFocusedIndex((prevIndex) => (prevIndex === null || prevIndex === itemCount - 1 ? 0 : prevIndex + 1));
                break;
            case 'ArrowUp':
                event.preventDefault();
                setFocusedIndex((prevIndex) => (prevIndex === null || prevIndex === 0 ? itemCount - 1 : prevIndex - 1));
                break;
            case 'Home':
                event.preventDefault();
                setFocusedIndex(0);
                break;
            case 'End':
                event.preventDefault();
                setFocusedIndex(itemCount - 1);
                break;
            case 'Escape':
                handleResetFilterStatus();
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        if (focusedIndex !== null && menuItemsRef.current[focusedIndex]) {
            menuItemsRef.current[focusedIndex]?.focus();
        }
    }, [focusedIndex]);

    return (
        <Container disableGutters maxWidth={false} sx={{ ...CustomTableStyle.tableTopButtons, overflow: "hidden" }} >
            <Box >
                {
                    //Check pageName another way
                    (pageName === "user-management" || pageName === 'batch-printing' || (pageName === 'agency-management' && STATE !== "ga")) &&
                    <Button
                        onClick={() => handler()}
                        aria-label={buttonLabel}
                        size="medium"
                        color="secondary"
                        disabled={disabled}
                        variant="contained"
                        startIcon={headerButtonIcon}
                        sx={CustomTableStyle.printButtonStyle}>
                        {text}
                    </Button>
                }
                {
                    filter && filterList.length > 0 && (
                        <>
                            <Tooltip title="Table Filter">
                                <IconButton onClick={(e) => handleOpenFilter(e)} sx={{ color: 'text.secondary' }} aria-label="button">
                                    <Badge color="primary" badgeContent={numFiltersDeselected > 0 ? numFiltersDeselected : null}>
                                        <FilterAltIcon />
                                    </Badge>
                                </IconButton>
                            </Tooltip>
                            <Menu
                                onClose={(e) => { handleCloseFilter(e); }}
                                slotProps={{
                                    paper: { sx: { border: "none", display: 'flex', flexDirection: "column" } }
                                }}
                                open={openFilter}
                                anchorEl={filterAnchorEl}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                MenuListProps={{
                                    'aria-labelledby': 'accessible-button',
                                    role: 'menu',
                                    onKeyDown: handleMenuKeyDown,
                                }}>

                                {filterList?.map((filter: any, index: any) => {
                                    const columnStateFilterValue = columnState.page[pageName][tableName]?.filters[index].filterValues
                                    const filterLabel = Object.keys(filter.filterLabel).map(
                                        (key: any) => filter.filterLabel[key].label
                                    )[0];
                                    const filterKey = Object.keys(filter.filterLabel).map(
                                        (key: any) => key
                                    )[0];
                                    const allSelected = Object.values(columnStateFilterValue).every(
                                        (value: any) => value.selected
                                    );
                                    const anySelected = Object.values(columnStateFilterValue).some(
                                        (value: any) => value.selected
                                    );

                                    return (
                                        <>
                                            <MenuItem
                                                key={index}
                                                disableRipple
                                                autoFocus={index === 0}

                                                sx={{
                                                    pl: "0.3rem",
                                                    "&:hover": {
                                                        backgroundColor: "transparent",
                                                        cursor: "text",
                                                    },
                                                }}
                                            >
                                                <Checkbox checked={allSelected} indeterminate={!allSelected && anySelected} inputProps={{ "aria-label": allSelected ? "Deselect all" : "Select all" }}
                                                    onKeyDown={(e) => {
                                                        if (e.key === "Enter" || e.key === " ") {
                                                            e.preventDefault();
                                                            handleColumnUpdate(filterKey, true, filter.filterColumn, filterLabel, { allSelected, anySelected })
                                                        }
                                                    }}

                                                    onClick={() => { handleColumnUpdate(filterKey, true, filter.filterColumn, filterLabel, { allSelected, anySelected }) }} />
                                                <Typography sx={{ flex: 5, mr: pageName === "rapback-validations" ? "0.5rem" : "" }}>
                                                    {filterLabel}
                                                </Typography>
                                                <Tooltip title={openFilters[index] ? "Collapse" : "Expand"}>
                                                    <IconButton onClick={() => toggleFilterMenu(index)}>
                                                        {openFilters[index] ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                                                    </IconButton>
                                                </Tooltip>
                                            </MenuItem>
                                            <Divider />
                                            {openFilters[index] &&
                                                Object.keys(filter.filterValues).map((key: any, innerIndex: any) => {
                                                    const isChecked = columnState.page[pageName][tableName]?.filters[index].filterValues[key]?.selected;
                                                    return (
                                                        <MenuItem sx={{ pl: '1.5rem' }} key={innerIndex} onClick={(e) => { e.stopPropagation(); handleColumnUpdate(key, isChecked, filter.filterColumn) }}>
                                                            <FormControlLabel
                                                                control={<Checkbox checked={isChecked} onKeyDown={(e) => {
                                                                    if (e.key === "Enter" || e.key === " ") {
                                                                        e.preventDefault(); handleColumnUpdate(key, isChecked, filter.filterColumn)
                                                                    }
                                                                }} onClick={(e) => { e.stopPropagation(); handleColumnUpdate(key, isChecked, filter.filterColumn) }} />}
                                                                label={filter.filterValues[key]?.label}
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                }}
                                                            />
                                                        </MenuItem>
                                                    );
                                                })}
                                        </>
                                    );
                                })}
                                <Grid sx={{ display: 'flex', justifyContent: 'center', mt: '1rem', mb: '1rem' }}>
                                    <Button variant="outlined" sx={{ width: '80%' }} color='primary' onClick={handleApplyFilter} tabIndex={0} aria-labelledby='apply-filter-button'>
                                        APPLY FILTER(s)
                                    </Button>
                                </Grid >
                            </Menu>
                        </>
                    )
                }
                <Tooltip title="Reset Table">
                    <IconButton onClick={(e: any) => {
                        e.stopPropagation();
                        handleReset?.() //Resets Column visibility and Status Filter
                        resetTable() //Resets table search
                    }}
                        sx={CustomTableStyle.iconContainer}>
                        <RestartAltIcon sx={{ color: 'action.active' }} aria-label="reset-table" />
                    </IconButton>
                </Tooltip>
                {
                    manageColumns && (
                        <>
                            <Tooltip title="Manage Columns">
                                <IconButton onClick={(e: any) => {
                                    e.stopPropagation();
                                    handleOpenManageColumns(e)
                                }}
                                    sx={CustomTableStyle.iconContainer}>
                                    <SettingsIcon sx={{ color: 'action.active' }} aria-label="manage-columns" />
                                </IconButton>
                            </Tooltip>
                            <Menu
                                onClose={(e) => handleCloseManageColumns(e)}
                                slotProps={{ paper: { sx: { backgroundColor: "background.default", border: "none", display: 'flex', flexDirection: "column" } } }}
                                id={manageColumnsId}
                                open={openManageColumns}
                                disableAutoFocus
                                anchorEl={mcAnchorEl}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                MenuListProps={{ 'aria-labelledby': 'basic-button', }}>
                                <MenuItem key="title" sx={{ pointerEvents: 'none' }}>
                                    <Container disableGutters sx={{ width: "100%", display: "flex" }}>
                                        <Typography sx={{ flex: 5 }}>Manage Columns</Typography>
                                    </Container>
                                </MenuItem>
                                <Divider />

                                {
                                    (tableHeaderCells?.map((cell: any, index) => {
                                        if (cell.dataIndex !== "checkbox") {
                                            return (
                                                <MenuItem key={index} onClick={() => {
                                                    handleColumnUpdate(cell.dataIndex, columnState.page[pageName][tableName]?.columnVisibility[cell.dataIndex]);
                                                }}>
                                                    <FormControlLabel
                                                        control={<Switch
                                                            checked={columnState.page[pageName][tableName]?.columnVisibility[cell.dataIndex]}
                                                            onClick={() => {
                                                                handleColumnUpdate(cell.dataIndex, columnState.page[pageName][tableName]?.columnVisibility[cell.dataIndex]);
                                                            }}
                                                            color='secondary'

                                                        />}
                                                        label={cell.dataIndex === 'checkbox' ? 'Checkbox' : cell.label}
                                                        disabled={cell.dataIndex === 'checkbox' ? true : false}
                                                        onClick={() => {
                                                            handleColumnUpdate(cell.dataIndex, columnState.page[pageName][tableName]?.columnVisibility[cell.dataIndex]);
                                                        }}
                                                    />
                                                </MenuItem>
                                            )
                                        }
                                    })
                                    )
                                }
                                <MenuItem onClick={() => { showAllColumns ? handleHideAll?.() : handleShowAll?.() }} sx={{
                                    display: 'inline-block',
                                    width: '50%',
                                    justifyContent: 'center',
                                    textAlign: 'center',
                                }}>
                                    {
                                        showAllColumns
                                            ? <Button variant='text' disableRipple style={{ backgroundColor: 'transparent' }}>HIDE ALL</Button>
                                            : <Button color='primary' variant='text' disableRipple style={{ backgroundColor: 'transparent' }}>SHOW ALL</Button>
                                    }
                                </MenuItem>
                                <MenuItem onClick={() => columnStateDispatch({ type: ColumnStateActions.RESET_COLUMNS, page: pageName, columnVisibility, tableName })} sx={{
                                    display: 'inline-block',
                                    width: '50%',
                                    justifyContent: 'center',
                                    textAlign: 'center'
                                }}>
                                    <Button variant='text' disableRipple style={{ backgroundColor: 'transparent' }}>RESET</Button>
                                </MenuItem>
                            </Menu>
                        </>
                    )
                }
            </Box>
        </Container>
    )
}
