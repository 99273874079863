import React, { useEffect, useReducer, useState } from 'react';
import { CustomTable } from 'common/CustomTable/CustomTable';
import { Paper } from '@mui/material'
import { CustomTableTitleAndAlert } from '../../common'
import { useExpanded } from 'components/layouts/ProtectedLayout';
import { useBatchPrint, useSetConfig } from "hooks"
import PrintIcon from '@mui/icons-material/Print';
import { selectAllReducer } from './helpers';
import { PrintModal } from './components'
import { Order } from '@customTypes/index';
import { SelectAll } from 'interfaces/pages/components';
import { pageStyles } from 'customStyles/common';
import { useTableStore } from 'state/TableStore';
import { useLocation } from 'react-router-dom';
import { extractPageName } from 'common/CustomTable/helpers';
import { useUserStore } from 'state';
import { TableNames } from 'customEnums/TableNames';

export const BatchPrinting: React.FC = () => {
    const { expanded } = useExpanded()
    const [currentPage, setCurrentPage] = useState(1);
    const [disableHeader, setDisableHeader] = React.useState(true)
    const [value, setValue] = React.useState<any>([]);
    const [searched, setSearched] = useState(false)
    const [openNotification, setOpenNotification] = useState<boolean>(false);
    const pagination = useTableStore((state) => state.pagination)
    const setPagination = useTableStore(state => state.setPagination)
    const setHeaderButtonConfig = useTableStore(state => state.setHeaderButtonConfig)
    const tcns = useTableStore(state => state.tcns)
    const setTcns = useTableStore(state => state.setTcns)
    const selected = useTableStore(state => state.selected)
    const setSelected = useTableStore(state => state.setSelected)
    const open = useTableStore(state => state.open)
    const setOpenPrint = useTableStore(state => state.setOpenPrint)
    const simpleSearchRan = useTableStore((state) => state.simpleSearchRan)


    const initialSelectAllState = {
        pages: [{ page: 1, selectAll: false }] as SelectAll[]
    }
    const [selectAllState, dispatchSelectAll] = useReducer(selectAllReducer, initialSelectAllState)
    const [visibleRows, setVisibleRows] = React.useState([])
    const userConfig = useUserStore(state => state.userConfig)
    const { usersPermissions } = userConfig;

    const searchParams = useTableStore((state) => state.searchParams)
    const displayNoSearchResults = useTableStore((state) => state.displayNoSearchResults)
    const setDisplayNoSearchResults = useTableStore(state => state.setDisplayNoSearchResults)
    const locationObj = useLocation()
    const pageName = extractPageName(locationObj.pathname)

    const setTableConfig = useTableStore(state => state.setTableConfig)
    const tableConfig = useTableStore(state => state.tableConfig)

    const { tableHeaderCells, page, rowsPerPage } = useSetConfig(pageName, { page: 1, rowsPerPage: 25 }, TableNames.BATCH_PRINTING, {})

    const batchPrintHeaderButtonConfig = {
        handler: () => handleOpenPrint,
        headerButtonIcon: <PrintIcon />,
        buttonLabel: "Print-Records",
        disabled: true,
        text: "Print"
    }

    useEffect(() => {
        setHeaderButtonConfig(batchPrintHeaderButtonConfig)
        console.log(`debug visibleRows page ${page}, rowsPerPage: ${rowsPerPage}`)
    }, [])

    const {
        data: mappedData,
        totalRecords,
        loading
    } = useBatchPrint(rowsPerPage, page, searchParams)

    useEffect(() => {
        console.log('batch print flow selected udpated: ', selected)
        console.log(`batch print flow mappedData: `, mappedData)
    }, [selected, mappedData])

    useEffect(() => {
        if (mappedData.length === 0 && simpleSearchRan) {
            setDisplayNoSearchResults(true)
            setOpenNotification(true)
        }
    }, [mappedData.length, simpleSearchRan])

    useEffect(() => {
        if (displayNoSearchResults) {
            setTimeout(() => {
                setDisplayNoSearchResults(false)
                setOpenNotification(false)
            }, 10000)
        }
    }, [displayNoSearchResults])

    const handleOpenPrint = () => {
        console.log('batch print flow handleOpenPrint')
        setOpenPrint(true)
    }

    const handleClose = () => {
        setOpenPrint(false)
        setSelected([])
        setTcns([])
        setHeaderButtonConfig({ ...batchPrintHeaderButtonConfig, disabled: true })
        dispatchSelectAll({ type: "DESELECT_ALL", page: currentPage })
    }

    const handleDeselectAll = () => {
        setTcns([])
    }

    const handleRowClick = (e: any, row?: any) => {
        console.log(`debug checkbox batch print flow handleRowClick: `, { row })
        handlePrintRecordSelect({ event: e, tcn: row.transactionNumber, id: row.key });
        handleClick(e, row.transactionNumber)
    }


    const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
        const batchPrintHeaderButtonConfig = {
            handler: () => handleOpenPrint(),
            headerButtonIcon: <PrintIcon />,
            buttonLabel: "Print-Records",
            disabled: true,
            text: "Print"
        }

        const selectedIndex = selected.indexOf(id);
        let newSelected: string[] = [];
        // console.log(`${label} handleClick tcn ${id} newSelcted ${newSelected} selectedIndex: ${selectedIndex}`)
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        if (newSelected.length > 0) {
            setHeaderButtonConfig({ ...batchPrintHeaderButtonConfig, disabled: false })
        } else {
            setHeaderButtonConfig({ ...batchPrintHeaderButtonConfig, disabled: true })
        }
        setSelected(newSelected);
    };

    const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
        console.log('batch print flow handleSelectAll selecting all tcns: ')
        console.log(`select all pagination current page: ${currentPage}`)
        console.log(`select all pagination visible`)
        let tcns: any = [] //TCNS get reset every time here
        if (e.target.checked) {
            dispatchSelectAll({ type: "SELECT_ALL", page: currentPage })
            mappedData.forEach((record: any) => {
                tcns.push(record)
            })
            console.log('batch print flow selecting all: ', tcns)

        } else {
            dispatchSelectAll({ type: "DESELECT_ALL", page: currentPage })
            tcns = []
            setHeaderButtonConfig({ ...batchPrintHeaderButtonConfig, disabled: true })
            console.log('batch-printing tcns handleSelectAll deSelecting all tcns')
        }
        console.log('batch print flow final tcns handleSelectAll: ', tcns)
        setTcns(tcns)
    }

    const handlePrintRecordSelect = (params: any) => {
        const { event, tcn } = params

        console.log(`debug checkbox batch print flow handlePrintRecordSelect params: `, { params })

        event.stopPropagation()
        const tcnList = tcns

        if (tcnList.some((row: any, index: number) => row.transactionNumber === tcn)) {
            console.log(`batch print flow handlePrintRecordSelect deleting tcn: ${tcn}`)
            let itemIndex
            tcns.forEach((item, index) => {
                console.log(`batch print flow index ${index} item: `, item)
                if (item.transactionNumber === tcn) {
                    itemIndex = index
                }
            })

            console.log('batch print flow removal item indx: ', itemIndex)

            tcnList.splice(itemIndex, 1)
            console.log(`batch print flow handlePrintRecordSelect updated list: ${JSON.stringify(tcnList, null, 2)}}`)
        } else {
            mappedData.forEach((row: any) => {
                if (row.transactionNumber === tcn) {
                    tcnList.push(row)
                    console.log(`batch print flow handlePrintRecordSelect adding tcn: ${tcn} list: ${tcnList}`)

                }
            })
        }

        if (tcnList.length < 1) {
            setHeaderButtonConfig({ ...batchPrintHeaderButtonConfig, disabled: true })
        }

        console.log('batch print flow handlePrintRecordSelect set tcns: ', tcnList)
        setTcns(tcnList)
    }

    const handlePageChange = (page: number) => {
        console.log('handlePageChange: ', page)
        setCurrentPage(page)
    }

    return (
        <>
            {
                usersPermissions?.viewBatchPrinting
                    ?
                    <Paper elevation={0} sx={{ ...pageStyles.pagePaperStyle, minWidth: `calc(100vw - ${expanded ? "256px" : "64px"})` }}>
                        <CustomTableTitleAndAlert name="Batch Printing" />
                        {
                            tableHeaderCells &&
                            <CustomTable
                                tableConfig={tableConfig}
                                tableName="batch-printing"
                                loading={loading}
                                rows={mappedData}
                                totalRecords={totalRecords}
                                currentPage={page}
                                pageSize={rowsPerPage}
                                searched={searched}
                                value={value}
                                setValue={setValue}
                                setSearched={setSearched}
                                handleDeselectAll={handleDeselectAll}
                                //Per page props
                                setDisableHeader={setDisableHeader}
                                tcns={tcns}
                                headerButtonHandler={handleOpenPrint}
                                headerButtonText="Print"
                                headerButtonIcon={<PrintIcon />}
                                headerButtonAriaLabel="print-record(s)"
                                handleTableItemSelect={handlePrintRecordSelect}
                                handleSelectAll={handleSelectAll}
                                savedSearchState={[{}, () => { }]}
                                selected={selected}
                                setSelected={setSelected}
                                selectAllState={selectAllState}
                                rowClickHandler={(e, row) => handleRowClick(e, row)}
                            />
                        }

                        {open && (
                            <PrintModal
                                totalRecords={totalRecords}
                                label="batch-printing"
                                handleOpenPrint={handleOpenPrint}
                                rows={mappedData}
                                handleClose={handleClose}
                                open={open}
                            />
                        )
                        }
                    </Paper>
                    :
                    null
            }
        </>
    )
}